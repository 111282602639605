import { SetRailsInwardSearchRequest, SetRailsInwardSearchRequestZ } from 'src/types/rails'

import { getGGClient } from './GetGoingClient'

export async function setRailsInwardSearch(searchId: number, payload: SetRailsInwardSearchRequest) {
	return getGGClient()
		.request('POST', `rails/search/${searchId}/search_inward`, {
			requestSchema: SetRailsInwardSearchRequestZ,
			data: payload,
		})
		.then((r) => r.data)
}
