import { z } from 'zod'

import { CreateJobResponseZ, getJobResultZ } from '../job'
import { UserIdZ } from '../user'
import { FlightFareGroupKeyZ, FlightOptionKeyZ, FlightSearchIdZ } from './common'

export const FlightFaresPricingCreateJobRequestZ = z.array(
	z.object({
		fare_group_key: FlightFareGroupKeyZ,
		flight_option_keys: z.array(FlightOptionKeyZ),
		user_id: UserIdZ,
		search_id: FlightSearchIdZ,
		fare_families: z.boolean().optional(),
	}),
)

export type FlightFaresPricingCreateJobRequest = z.infer<typeof FlightFaresPricingCreateJobRequestZ>

export const FlightFaresPricingCreateJobResponseZ = CreateJobResponseZ

export type FlightFaresPricingCreateJobResponse = z.infer<typeof FlightFaresPricingCreateJobResponseZ>

export const FlightFaresPricingJobResultResponseZ = getJobResultZ(z.any()) // I had no type to convert all the pricing types to Zod

export type FlightFaresPricingJobResultResponse = z.infer<typeof FlightFaresPricingJobResultResponseZ>
