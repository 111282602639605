import { AbortRequestSignal } from 'src/lib/requests/getAbortRequestSignal'
import { BookingOption } from 'src/organisms/FlightSearchNew/types'
import { PricingJobResult } from 'src/organisms/FlightSearchNew/useLoadFares'
import { FlightSearchId } from 'src/types/flights'
import {
	FlightFaresPricingCreateJobRequestZ,
	FlightFaresPricingCreateJobResponseZ,
	FlightFaresPricingJobResultResponseZ,
} from 'src/types/flights/fare'
import { JobStatus } from 'src/types/job'

import { getGGClient } from './GetGoingClient'

export async function getFlightFaresPricing(
	bookingOptions: BookingOption[],
	searchId: FlightSearchId,
	fareFamilies?: boolean,
	opts?: { signal: AbortRequestSignal },
): Promise<PricingJobResult> {
	const url = `flights/pricing` as const
	const { signal } = opts ?? {}
	const { job_id } = await getGGClient()
		.request('POST', url, {
			requestSchema: FlightFaresPricingCreateJobRequestZ,
			responseSchema: FlightFaresPricingCreateJobResponseZ,
			data: bookingOptions.map((bo) => {
				return {
					fare_group_key: bo.fare_group_key,
					// Always index = 0 because `flight_option_keys` is an object per traveler
					// and for the fare pricing a single traveler is enough. Probably...
					flight_option_keys: bo.flight_option_keys![0].flight_option_keys,
					user_id: bo.flight_option_keys![0].traveler_id,
					search_id: searchId,
					fare_families: fareFamilies,
				}
			}),
			cancelToken: signal?.cancelToken,
		})
		.then((r) => r.data)

	return getGGClient().pollJobResult(url, {
		params: {
			job_id,
			search_id: searchId,
		},
		responseSchema: FlightFaresPricingJobResultResponseZ,
		mapResult: ({ data }, poll) => {
			if (data.status === JobStatus.Completed) {
				return data.result
			}
			if (data.status === JobStatus.Failed) {
				throw new Error(data.errors.join('; '))
			}
			return poll
		},
		cancelToken: signal?.cancelToken,
	})
}
