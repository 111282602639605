import moment from 'moment'
import React from 'react'
import { RouteComponentProps, StaticContext } from 'react-router'
import { z } from 'zod'

import { CountriesCode } from 'src/i18nConfig'
import { E2E } from 'src/lib/e2e-utils'
import { TFunction } from 'src/lib/i18n/i18n'
import { LegacyJobStatus } from 'src/lib/requests/types'
import { Routes, RouteState } from 'src/lib/route-utils'
import { SeatMapFlightResultSelection, SeatMapSegmentsSelection } from 'src/lib/seatmap'
import { Maybe } from 'src/lib/types'
import { capitalize } from 'src/lib/utils'
import { ExpenseReportApi } from 'src/travelsuit/expenses'
import { FareComponent } from 'src/travelsuit/fare-rules'
import { SeatMapSeat } from 'src/travelsuit/seatmaps'
import { SupportRequest } from 'src/travelsuit/support-request'
import { UserRailCard } from 'src/travelsuit/trains'
import { ErrorsInBooking } from 'src/travelsuit/trips'
import { BillingType, FeatureScope, PackageStatus, TripContentAllowance } from 'src/types/billing'
import { Currency, Gender } from 'src/types/common'
import { CompanyFeatureFlags, CompanyPreferenceType, CompanyType } from 'src/types/company'
import {
	CreditCardBillingData,
	CreditCardId,
	CreditCardOwnerType,
	CreditCardStatus,
	CreditCardType,
	UsageAllowedFor,
} from 'src/types/creditCard'
import { BookingOptionUTA, FlightBookingSegmentResId, FlightFareGroupKey, FlightOptionKey } from 'src/types/flights'
import { JobStatus } from 'src/types/job'
import { Locales } from 'src/types/locale'
import { PersonNames, UserId, UserStatus } from 'src/types/user'

import {
	CarAdditionalDetailsName,
	CarAgencyLocation,
	CarCancellationPolicy,
	CarFee,
	CarFuel,
	CarRate,
	CarRateDescription,
	CarRentAgency,
	CarSpecialEquipment,
	CarTransmission,
	CarType,
	CarVendor,
	Mileage,
} from './cars.type'
import { ReportingFields } from './report-settings.type'
import { City } from './sites'
import { WordlineOrderStatus } from './wordline'

export { PackageStatus, FeatureScope } from 'src/types/billing'
export type LocationId = string | number

export const WEBSITE_BASE = 'getgoing.com'

export type AuthUser = auth0.Auth0UserProfile
export type EmptyCallback<R = void> = () => R
export type Callback<R = void, A = any> = (arg: A) => R
export type CallbackOptional<R = void, A = any> = (arg?: A) => R
export type EmptyVoidCallback = EmptyCallback<void>
export type VoidCallback<T = any> = Callback<void, T>
export type ComparisonFn<T> = (a: T, b: T) => boolean
export type SortingFn<T> = (a: T, b: T) => number
type RequireExcept<T, K extends keyof T> = Omit<Required<T>, K> & Pick<T, K>
export type PartialRequireExcept<T, K extends keyof T> = Partial<RequireExcept<T, K>>
export type WithTransformation<T> = unknown & { readonly __original__: T }
/** Either `T` or `Array<T>` */
export type ArrayOr<T> = T | T[]
/** Get array values, e.g.
 * ```
 * const validPropsNames: Array<'id' | 'name' | 'email'> = ['id']
 * type PossiblePropNames = ArrayValue<typeof validPropsNames> // 'id' | 'name' | 'email'
 * ```
 */

export interface WithGenericId<T> {
	id: T
}

export type Identifiable = WithGenericId<number>

export type LongtitudeLatitude = { lng: number; lat: number }

export type FitBounds = [[number, number], [number, number]]

export interface LongtitudeLatitudeBounds {
	getSouthWest: () => LongtitudeLatitude
	getNorthEast: () => LongtitudeLatitude
}

export const CurrencySigns: Record<Currency, string> = {
	[Currency.USD]: '$',
	[Currency.EUR]: '€',
	[Currency.GBP]: '£',
	[Currency.AUD]: 'AU$',
	[Currency.SEK]: 'kr',
	[Currency.ILS]: '₪',
}

export const LocaleToCurrency: Record<Locales, Currency> = {
	[Locales.enUS]: Currency.USD,
	[Locales.enGB]: Currency.USD,
	[Locales.deDE]: Currency.EUR,
}

export const CARD_TYPES_WITHOUT_REQUIRED_CVV = [
	CreditCardType.AirPlus,
	CreditCardType.AmexBTA,
	CreditCardType.AmexBTAGermany,
]

// TODO: Check with BA
export const CVV_LENGTH_BY_CARD_TYPE = {
	[CreditCardType.Visa]: 3,
	[CreditCardType.MasterCard]: 3,
	[CreditCardType.AmericanExpress]: 4,
	[CreditCardType.Diners]: 3,
	[CreditCardType.AirPlus]: 3,
	[CreditCardType.AmexBTA]: 4,
	[CreditCardType.AmexBTAGermany]: 4,
}

export const CARD_NUMBER_LENGTH_BY_CARD_TYPE: { [key in CreditCardType]: number[] } = {
	[CreditCardType.Visa]: [16],
	[CreditCardType.MasterCard]: [16],
	[CreditCardType.Diners]: [14, 15, 16, 17, 18, 19],
	[CreditCardType.AirPlus]: [15, 16],
	[CreditCardType.AmexBTA]: [15],
	[CreditCardType.AmexBTAGermany]: [15],
	[CreditCardType.AmericanExpress]: [15],
	[CreditCardType.Other]: [],
}

export const VAT_COEFFICENT = 1.17
export interface Money {
	amount: string
	currency: Currency
}

export interface WithUserId<T = number> {
	user_id: T
}

export interface WithCreditCardId<T = number> {
	credit_card_id: T
}

export interface BillingCost extends Money {
	billing_date: string | null
}

export interface CreditCardInfo {
	id: CreditCardId
	card_number: string
	card_holder_name: string
	card_type: CreditCardType
	card_expiration_date: string
	card_name: string
	allow_use_my_card?: boolean
	card_data?: CreditCardBillingData
	used_for_package_payment?: boolean
	cvc_exist: boolean | null
	reporting_fields?: ReportingFields[]
	default_for_fees?: boolean
	status: CreditCardStatus
}

export enum LoginStatus {
	CompanyDoesNotExist = 1,
	UserDoesNotExist = 0,
	UserExists = 2,
	NotAcceptedTos = 3,
}

export interface LoginDetails {
	user: User
	login_status: LoginStatus
	is_impersonation: boolean
	user_can_operate: boolean
}

export interface SessionInfo {
	valid: boolean
	expires_at: Date
}

export interface WithCardCvc<T = string> {
	card_cvc?: T
}

export interface CreateCreditCardData extends WithCreditCardPermissions, WithUserId<Maybe<number>>, WithCardCvc {
	card_holder_name: string
	card_name: string
	card_number: string
	card_expiration_date: string
	card_type: CreditCardType
	card_data: CreditCardBillingData
	allow_use_my_card: boolean
	related_to: CreditCardOwnerType
	invoice_profile_id?: number | null
	temporary_card?: boolean
	cost_center?: boolean
	employee_id?: boolean
	department?: boolean
	accounting_unit?: boolean
	action_code?: boolean
	purchase_order_number?: boolean
	project_code?: boolean
	default_for_fees?: boolean
	mock: string | null
}

export interface CreditCardVerificationData {
	worldline_method_code: string
	worldline_token: string
	worldline_validated: boolean
	scheme_transaction_id: string | null
}

export interface OrderVerificationData extends WithCardCvc<string | null> {
	worldline_validated: boolean
	trip_id?: number | null
}

export interface WithCreditCardPermissions {
	usage_allowed_for: UsageAllowedFor | null
	available_only_for_travel_arrangers: boolean
	permitted_for_flights: boolean
	permitted_for_hotels: boolean
	permitted_for_cars: boolean
	permitted_for_fees: boolean
}

export interface CreditCardResponse extends WithCreditCardPermissions {
	card_data?: CreditCardBillingData
	card_expiration_date: string
	card_type: CreditCardType
	id: CreditCardId
	card_holder_name: string
	card_number: string
	allow_use_my_card: boolean
	card_name: string
	related_to: CreditCardOwnerType
	cvc_exist: boolean | null
	status: CreditCardStatus
	used_for_package_payment?: boolean
	cost_center?: boolean
	employee_id?: boolean
	department?: boolean
	accounting_unit?: boolean
	action_code?: boolean
	purchase_order_number?: boolean
	project_code?: boolean
	default_for_fees?: boolean
	invoice_profile?: { id: number; display_name: string } | null
	user?: { id: number; first_name: string; last_name: string; middle_name: string | null } | null
}

interface WithCreditCards<T = CreditCardResponse[]> {
	credit_cards: T
}

/** Used on search, corresponds to BookingSegmentProductType on the backend */
export enum ProductType {
	Flights = 'flights',
	Hotels = 'hotels',
	Cars = 'cars',
	Rails = 'rails',
}

export const ProductTypeZ = z.nativeEnum(ProductType)

export function getProductTypeLabel({ productType, t }: { productType: ProductType; t: TFunction }) {
	return {
		[ProductType.Flights]: t('trip-content-types.flights'),
		[ProductType.Hotels]: t('trip-content-types.hotels'),
		[ProductType.Cars]: t('trip-content-types.cars'),
		[ProductType.Rails]: t('trip-content-types.trains'),
	}[productType]
}

export interface SearchData<T = AnySearchDetails> extends WithTravelers {
	type: ProductType
	details: T
}

export interface SearchRequestWithTrain {
	type: ProductType
	details?:
		| FlightSearchRequestDetails
		| HotelSearchRequestDetails
		| CarSearchRequestDetails
		| RailSearchDetails
		| RailsSearchRequestDetails
	travelers_ids: number[]
	trip_id: number
	old_search_id?: number
}

export interface RailInwardSearchRequest {
	search_id: string
	outward_journey_id: string
	outward_alternative_ids: string[]
}

interface WithAirlineAlliances {
	airline_alliances: string[] | null
}

interface WithAirlines {
	airlines: Airline[] | null
}

export interface FlightSearchDetails extends WithAirlineAlliances, WithAirlines {
	routes: FlightSearchRouteDetails[]
	flight_type: FlightDirection
	cabin_class: CabinClassName
	max_num_of_connections: number
	trip_id?: number | null
	fully_flexible?: boolean
	refundable_only?: boolean
	changeable_only?: boolean
	included_baggage?: boolean
}

export interface WithName {
	name: string
}

export interface LocationForRecentSearch extends WorldPoint, WithName {
	address: string | null
	airport_name: null
	country: { code: string } & WithName
	country_name?: string
	id: number | null
	short_name: boolean
	state_code: string | null
}

export enum TimeRestrictionType {
	Arrival = 'arrival',
	Departure = 'departure',
}

export interface TimeRestriction {
	time: string
	time_type: TimeRestrictionType | null
	time_window: number
}

interface FlightSearchRouteDetailsForRecentBase {
	from_location: LocationForRecentSearch | null
	to_location: LocationForRecentSearch | null
	from_location_type: LocationTypes
	to_location_type: LocationTypes
}

export interface WithFlightDepartureSettings {
	departure_date: string | null
	time_restriction: TimeRestriction | null
}

export type FlightSearchRouteDetailsForRecent = FlightSearchRouteDetailsForRecentBase &
	WithFlightDepartureSettings & {
		connecting_locations: LocationDetails[] | null
	}

export interface FlightSearchRequestDetailsBase<T> extends WithAirlineAlliances {
	routes: T[]
	flight_type: FlightDirection
	cabin_class: CabinClassName
	max_num_of_connections: number
}

export type FlightSearchDetailsForRecent = FlightSearchRequestDetailsBase<FlightSearchRouteDetailsForRecent> &
	WithAirlines

export type FlightSearchRequestDetails = FlightSearchRequestDetailsBase<FlightSearchRequestRouteDetails> & {
	airline_codes: string[] | null
}

export type RailsSearchRequestDetails = {
	rails_type: TripDirection
	routes: RailsSearchRequestRouteDetails[]
}

export interface LocationDetails {
	location: Location
	location_type: LocationTypes
}

export interface FlightSearchRouteDetails extends WithFlightDepartureSettings {
	from_location: Location | null
	to_location: Location | null
	key?: string
	connecting_locations: LocationDetails[] | null
	from_city?: City
	to_city?: City
}

interface FlightSearchRequestRouteDetailsBase {
	from_location: LocationId | null
	to_location: LocationId | null
	from_location_type: LocationTypes | null
	to_location_type: LocationTypes | null
}

interface ConnectingLocationRequestDetails {
	location_id: LocationId
	location_type: LocationTypes
}

interface FlightSearchRequestRouteDetails extends FlightSearchRequestRouteDetailsBase, WithFlightDepartureSettings {
	timezone_offset: number
	connecting_locations: ConnectingLocationRequestDetails[]
}

export interface RailsSearchRequestRouteDetails extends FlightSearchRequestRouteDetailsBase {
	departure_date: string | null
	connecting_locations: ConnectingLocationRequestDetails[]
}

export type RailsSearchRouteDetails = RailsSearchRequestRouteDetails

export interface HotelSearchDetails {
	near_location_type?: LocationTypes
	near_location: Location | null
	from_date: string | null
	until_date: string | null
	geo?: GeoSearchDetails
	term?: string
	radius: number | null
	radius_units: ShortDistanceUnits
	include_non_traditional_properties?: boolean
}

export interface HotelSearchRequestDetails {
	near_location_type: LocationTypes
	near_location: LocationId | null
	from_date: string | null
	until_date: string | null
	geo?: GeoSearchDetails
	term?: string
}

export interface CarSearchDetails {
	pickup_location: Location | null
	dropoff_location?: Location | null
	from_date: string | null
	until_date: string | null
	pickup_location_type?: LocationTypes | null
	dropoff_location_type?: LocationTypes | null
}
export interface CarSearchRequestDetails {
	pickup_location: LocationId | null
	dropoff_location?: LocationId | null
	pickup_location_type?: LocationTypes | null
	dropoff_location_type?: LocationTypes | null
	from_date: string | null
	until_date: string | null
}

export interface RailSearchDetails {
	rail_type: FlightDirection
	departure_station: RailStationDetails
	arrival_station: RailStationDetails
	departure_time: string
	train_class: string
	return_time?: string
}

export interface RailSearchDetailsNew {
	routes: RailsSearchRouteDetails[]
	rails_type: TripDirection
	card_id?: string
}

export interface RailStationDetails extends WithName {
	created_dt: string
	visibility: 'private' | 'public' | 'hidden'
	id: number
	lat: number
	long: number
	country: string | null
	airport_name: string
	address: string | null
	location_type?: LocationTypes.Rail
	short_name: string | null
	country_name: string | null
	number: number
}

export interface GeoSearchDetails {
	long: number
	lat: number
	radius: number
}

export type AnySearchDetails =
	| FlightSearchDetails
	| HotelSearchDetails
	| CarSearchDetails
	| RailSearchDetails
	| RailSearchDetailsNew

export type User = PersonNames & {
	id: UserId
	email: string
	traveler_profile?: TravelProfile
	user_roles?: UserRole[]
	user_permissions?: UserPermission[]
	image_url?: string
	bookable?: boolean
	auto_select_self: boolean
	language_code: Locales | null
	currency_code: Currency
	gender: Gender | null
	invoice_profile?: InvoiceProfile | null
	mock_enabled?: boolean
	tokenizer_mock_enabled?: boolean
	worldline_mock_enabled?: boolean
	is_admin?: boolean
	deactivate_at: Date | string | null
	deleted: boolean
	status: UserStatus
}

export type UserCreationPayload = Pick<
	User,
	'first_name' | 'last_name' | 'email' | 'middle_name' | 'invoice_profile'
> & {
	phone_number?: string | null
	role?: Role | null
	group?: Group | null
	office_location?: Location | null
	language_code?: Locales | null
	invoice_profile_id?: InvoiceProfile['id'] | null
	deactivate_at?: Date | string | null
}

export interface UserWithPolicies extends User {
	ignore_approval: boolean
	custom_policy: {
		id: number
	} & WithName
}

export interface UserPermission {
	roles: UserPermissionsTypes[]
}

export enum UserPermissionsTypes {
	Default = 'Default',
	ManageUsers = 'ManageUsers',
	Admin = 'Admin',
	ViewOthers = 'ViewOthers',
	ManageSystem = 'ManageSystem',
	ManageApprovals = 'ManageApprovals',
	ManageTrips = 'ManageTrips',
	ViewAllReports = 'ViewAllReports',
	ViewManagedGroupReports = 'ViewManagedGroupReports',
}

export interface PermissionDefs {
	every: UserPermissionsTypes[]
	oneOf: UserPermissionsTypes[]
	either: boolean
}

export interface UserRole extends WithUserId {
	id?: number
	role_id: number
	role?: PermissionRole
}

export interface UserPermission extends WithUserId {
	id: number
	permission: UserPermissionsTypes
	enabled: boolean
}

export enum UserRoleNames {
	ManageBookings = 'ManageBookings',
	SupportUser = 'Support',
	Admin = 'Admin',
}

export interface PermissionRole {
	id: number
	name: UserRoleNames
	description: string
	is_admin: boolean
}

export interface TravelProfile extends WithUserId, WithCreditCards {
	id: User['id']
	role: Role
	date_of_birth: Date | string | null
	employee_id: number | string | null
	gender: Gender | null
	home_location?: Location | null
	office?: Location | null
	home_location_id?: Location['id']
	office_id?: Location['id']
	emergency_contact_name: string | null
	emergency_contact_phone_number: string | null
	emergency_contact_email: string | null
	has_medical_condition: boolean
	flight_properties: object
	phone_number: string
	dial_code_country: CountryType
	frequent_flyer_cards?: FrequentFlyer[]
	hotel_loyalty_programs?: HotelLoyaltyProgramCard[]
	car_loyalty_programs?: CarLoyaltyProgramCard[]
	passports?: Passport[]
	national_ids?: NationalId[]
	title: string | null
	group?: Group | null
	group_id?: Group['id'] | null
	rail_cards: UserRailCard[]
	cost_center: string | null
	accounting_unit: string | null
	internal_account: string | null
	purchase_order_number: string | null
	custom_field_1: string | null
	custom_field_2: string | null
	custom_field_3: string | null
	kt_number: string | null
	ca_number: string | null
	redress_number: string | null
}

export enum ProfileType {
	Permanent = 'permanent',
	Temporary = 'temporary',
}

export interface GroupPurchasingOrganization extends WithName {
	id: number
}

export interface Company extends WithName {
	id: number
	logo_url: string | null
	flights_allowance: TripContentAllowance
	hotels_allowance: TripContentAllowance
	reports_enabled: boolean
	created_dt: string
	company_type: CompanyType
	billing_type: BillingType
	is_after_onboarding: boolean
	accepted_tos?: boolean
	preferences: CompanyPreference[]
	bmis: string | null
	recommended_package_id: number | null
	recommended_pricing_model_id: number | null
	company_contact_person: User | null
	country?: POSCountry
	number_of_reward_programs: number
	has_failed_fees?: boolean
	gpo: GroupPurchasingOrganization | null
	enable_non_traditional_hotel_display?: boolean
	feature_flag: CompanyFeatureFlags | null
}

export enum CompanyInvoiceStatus {
	Draft = 'draft',
	ConfirmationInProgress = 'confirmation_in_progress',
	TermsOfServiceValidationPending = 'validation_pending',
	Blank = 'blank',
	Active = 'active',
}

export interface InvoicePerson {
	id?: number
	first_name: string
	last_name: string
	email: string
	phone_number: string
}

export interface CountryLanguage extends WithName {
	code: Locales
	country_id: number
	id: number
}

export enum SubscriptionFeeType {
	CompanySubscription = 'company_subscription',
	User = 'user',
	UserAndCompanySubscription = 'user_company_subscription',
}

export enum PricingModelType {
	Subscription = 'SUBSCRIPTION',
	PayAsYouGo = 'PAY_AS_YOU_GO',
}

export enum FeeType {
	Trip = 'trip',
	SupportRequest = 'support_request',
	TripAndSupportRequest = 'trip_support_request',
}

export enum UserType {
	ActiveUser = 'active_user',
	ProfileUser = 'profile_user',
}

export enum FreePeriodType {
	None = 'none',
	OneTime = 'one_time',
	PerMonth = 'per_month',
	PerYear = 'per_year',
	OneTimePerMonth = 'one_time_per_month',
	OneTimePerYear = 'one_time_per_year',
}

export interface PricingCountryPos {
	trip_fee: number | null
	user_fee: number | null
	support_request_fee: number | null
	country_pos_id: number
	company_subscription_fee: number | null
}

export interface PricingModel {
	annual_payment_discount: number | null
	trip_period_type: FreePeriodType | null
	user_type: UserType | null
	package_id: number
	fee_type: FeeType | null
	subscription_type: SubscriptionFeeType | null
	support_request_value: number | null
	pricing_model_type: PricingModelType
	user_period_type: FreePeriodType | null
	user_value: number | null
	pricing_country_poses: PricingCountryPos[]
	support_request_one_time: number | null
	support_request_period_type: FreePeriodType | null
	trip_value: number | null
	trip_one_time: number | null
	user_one_time: number | null
	id: number
}

export const featureScopesAccessLevelOrder = [FeatureScope.Free, FeatureScope.TravelOnly, FeatureScope.TravelExpense]

export interface BillingPackage extends WithName {
	id: number
	comments: string | null
	start_date: string
	previous_package_id: number | null
	status: PackageStatus
	original_package_id: number | null
	feature_scope: FeatureScope
	stop_sell_date: string | null
	replaced_by_id: number | null
	pricing_models: PricingModel[]
	migrate_clients: boolean | null
}

export interface PosBillingSettings extends WithCreditCardId {
	invoice_profile_id: number
	pricing_model_id: number
	country_pos_id: number

	billed_annually: boolean
	active_user_fees_payment_config: ({ invoice_profile_id: number } & WithCreditCardId)[] | null
}

export interface PackagesAndBillingSettings {
	selected_pricing_models_by_pos: PosBillingSettings[]
}

export interface PaymentInfo {
	id: number

	billed_annually: boolean
	next_payment_billed_annually: boolean

	credit_card: CreditCardResponse | null
	country_pos: CountryPOS

	invoice_profile: InvoiceProfile | null

	package: BillingPackage
	next_payment_package: BillingPackage | null

	pricing_model: PricingModel
	next_pricing_model: PricingModel | null

	start_date: string | null
	next_payment_date: string | null

	active_user_fees_payment_config: { invoice_profile: InvoiceProfile; credit_card: CreditCardResponse }[] | null
}

export interface InvoiceProfile extends WithCreditCards<CreditCardResponse[] | null> {
	id: number

	contract_start_date?: string | null

	display_name: string | null
	description: string | null

	status: CompanyInvoiceStatus

	industry: string | null
	legal_entity_names: string[] | null

	tax_number: string | null
	trade_register_number: string | null

	building: string | null
	room: string | null
	floor: string | null

	streets: string[] | null
	number: string | null
	district: string | null
	postal_code: string | null
	city: string | null

	country: POSCountry | null
	country_pos_id: number | null
	language: CountryLanguage | null

	authorized_person_user: InvoicePerson | null
	default_email: string | null

	billing_contact_person_first_name: string | null
	billing_contact_person_last_name: string | null
	billing_contact_person_email: string | null
	billing_contact_phone_number: string | null

	state: string | null

	credit_cards: CreditCardResponse[] | null
}

export interface TripPaymentMethod {
	id: number
	credit_card: CreditCardResponse
}

export type CompanyDetails = Pick<Company, 'accepted_tos' | 'bmis'>

export interface FrequentFlyer extends WithUserId {
	id: number | null
	card_number: string
	airline: Airline
	airline_id: number
}

export enum Role {
	Employee = 'employee',
	Manager = 'manager',
	Executive = 'executive',
}

export function getRoleLabel({ role, t, count }: { role: Role; t: TFunction; count?: number }) {
	return {
		[Role.Employee]: t('roles.employee', { count }),
		[Role.Manager]: t('roles.manager', { count }),
		[Role.Executive]: t('roles.executive', { count }),
	}[role]
}

export const RolePriority: Record<Role, number> = {
	[Role.Employee]: 0,
	[Role.Manager]: 1,
	[Role.Executive]: 2,
}

export type TravelProfileKeys = keyof TravelProfile
export type UserKeys = keyof User

export type TripReportSettings = Record<ReportingFields, string> & WithUserId
export interface WithTripApprovalStatuses {
	approval_statuses: TripApproval[]
}

export type TripFeePaymentConfig = WithUserId & WithCreditCardId & Identifiable

export interface MinimalTrip {
	id: number
	name?: string | null
	status: TripStatus
}

export interface Trip extends WithTravelers, WithTripApprovalStatuses {
	purpose_list: TripPurposeOption[]
	id: number
	name?: string | null
	status: TripStatus
	calculatedStatus?: AllTripStatuses
	created_dt?: Date | string
	created_by?: number
	created_by_user?: User
	travelers_count: number
	flights_comments: string | null
	hotels_comments: string | null
	cars_comments: string | null
	products: Product[]
	trip_type: FlightDirection
	include_flights: boolean
	include_accommodations: boolean
	support_requests: SupportRequest[]
	pre_trip_request_id?: number
	purpose: TripPurpose[]
	total_cost: number
	total_cost_billed: BillingCost[]
	parent_trip_id?: number
	sub_trips: Trip[] // TODO: add TripBase
	start_date: moment.MomentInput
	end_date: moment.MomentInput
	fees?: Money
	cancellation_date?: Date | string
	report_settings?: TripReportSettings[]

	trip_fees_credit_cards: TripFeePaymentConfig[]
}

export interface SegmentToTraveler extends WithUserId {
	booking_segment_id: number
}

export interface TripInvoices {
	traveler: User
	invoices: Invoice[]
	products: TripProductType[]
}

interface Invoice {
	receipts: Receipt[]
	file_uri: string
	amount: string
	invoice_number: string
	issue_date: Date
	currency: string
	id: number
}

interface Receipt {
	payment_method: string
	receipt_number: string
	file_uri: string
	amount: string
	currency: string
	id: number
	issue_date: Date
}

export interface WithReservationStatus {
	reservation_status: ReservationStatus | null
}

export interface Product extends WithCancellationStatus, Partial<WithReservationStatus> {
	product_type: TripProductType
	start_time: string
	end_time: string
	from_location: Location
	to_location: Location
}

/**
 * Another ProductType we are getting from `GET /trip/{id}` endpoint as the `response.product.0.product_type` field.
 * Should have been BookingSegmentProductType, but got `hotel_rooms` by a mistake.
 * Should be refactored on both BE and FE sides to BookingSegmentProductType.
 */
export enum TripProductType {
	Rails = 'rails',
	Flight = 'flights',
	Hotel = 'hotel_rooms',
	Car = 'cars',
}

export enum LocationTypes {
	Home = 'home',
	Office = 'office',
	Client = 'client',
	Hotel = 'hotel',
	Airport = 'airport',
	Other = 'other',
	City = 'city',
	Country = 'country',
	CarRentAgency = 'car_rent_agency',
	TrainStation = 'train',
	Rail = 'rail',
}

export function getLocationTypeLabel({ locationType, t }: { locationType: LocationTypes; t: TFunction }) {
	return {
		[LocationTypes.Home]: t('location-type.home'),
		[LocationTypes.Office]: t('location-type.office'),
		[LocationTypes.Client]: t('location-type.client'),
		[LocationTypes.Hotel]: t('location-type.hotel'),
		[LocationTypes.Airport]: t('location-type.airport'),
		[LocationTypes.Other]: t('location-type.other'),
		[LocationTypes.City]: t('location-type.city'),
		[LocationTypes.Country]: t('location-type.country'),
		[LocationTypes.CarRentAgency]: t('location-type.car-rent-agency'),
		[LocationTypes.TrainStation]: t('location-type.train'),
		[LocationTypes.Rail]: t('location-type.rail'),
	}[locationType]
}

export interface WorldPoint {
	lat: number | null
	long: number | null
}

export interface Location extends WorldPoint, WithName {
	id: LocationId | null
	external_id?: string | null
	address: string
	location_data:
		| google.maps.places.PlaceResult
		| google.maps.places.AutocompletePrediction
		| { city_code?: string; iata?: string }
		| HotelSearchResult
		| null
	location_type: LocationTypes
	description: string | null
	main_contact_name: string | null
	main_contact_email: string | null
	main_contact_phone: string | null
	main_approver: User | null
	created_dt?: string
	visibility?: 'private' | 'public' | 'hidden'
	short_name?: string | null
	airport_name?: string | null
	country?: string
	country_name?: string
	country_code?: string
	city_name?: string
	city_code?: string
	iata_code?: string
	code?: string
	state_name?: string
	has_policy?: boolean
	group_id?: number
	number?: number
	users_count?: number
	state_code: string | null
}

export const CountryName = z.string()

export interface Country {
	name: z.infer<typeof CountryName>
	iso: string
	calling_code: string
}

enum LuggageStatus {
	None = 'NONE',
	Free = 'FREE',
	Paid = 'PAID',
}

export enum TripStatus {
	Draft = 'draft',
	DraftBySupport = 'draft_by_support',
	WaitingApproval = 'waiting_approval',
	Canceled = 'canceled',
	NotApproved = 'not_approved',
	Booked = 'booked',
	Booking = 'booking',
	Approved = 'approved',
	Reverted = 'reverted',
	PreTripGreenLight = 'pre_trip_green_light',
	SendingToApproval = 'sending_to_approval',
	PriceChanged = 'price_changed',
	WaitingForUserSubmission = 'waiting_for_user_submission',
	UrgentActionRequired = 'urgent_action_required',
}

export enum InvoiceProfileStatus {
	ConfirmationInProgress = 'confirmation_in_progress',
	TermsOfServiceValidationPending = 'validation_pending',
	Active = 'active',
}

export enum TripStatusVirtual {
	Upcoming = 'upcoming',
	TravelingNow = 'traveling_now',
	PastTrip = 'past_trip',
	Rejected = 'rejected',
}

export function getTripStatusLabel({
	status,
	count,
	t,
}: {
	status: TripStatus | TripStatusVirtual
	count?: number
	t: TFunction
}) {
	if (status === TripStatus.WaitingApproval) {
		return t('trips-page.status-list-titles.waiting-approval', 'Waiting Approval', { count })
	}

	if (status === TripStatus.Draft) {
		return t('trips-page.status-list-titles.draft', 'Draft', { count })
	}
	if (status === TripStatus.Booked) {
		return t('trips-page.status-list-titles.booked', 'Booked', { count })
	}
	if (status === TripStatus.NotApproved) {
		return t('trips-page.status-list-titles.not-approved', 'Not Approved', { count })
	}
	if (status === TripStatus.Canceled) {
		return t('trips-page.status-list-titles.canceled', 'Cancelled', { count })
	}
	if (status === TripStatusVirtual.PastTrip) {
		return t('trips-page.status-list-titles.past-trip', 'Past trip', { count })
	}

	return null
}

export const TripStatusLabelMap: Record<TripStatus & TripStatusVirtual & InvoiceProfileStatus, string> = {
	[TripStatus.Booked]: 'trip-statuses.booked',
	[TripStatus.Canceled]: 'trip-statuses.canceled',
	[TripStatus.Draft]: 'trip-statuses.draft',
	[TripStatus.DraftBySupport]: 'trip-statuses.draft-by-support',
	[TripStatus.WaitingApproval]: 'trip-statuses.pending-approval',
	[TripStatus.NotApproved]: 'trip-statuses.rejected',
	[TripStatusVirtual.TravelingNow]: 'trip-statuses.traveling-now',
	[TripStatusVirtual.Upcoming]: 'trip-statuses.upcoming',
	[TripStatusVirtual.PastTrip]: 'trip-statuses.past',
	[TripStatus.Reverted]: 'trip-statuses.booking-failed',
	[TripStatus.Booking]: 'trip-statuses.booking',
	[TripStatus.Approved]: 'trip-statuses.approved',
	[InvoiceProfileStatus.ConfirmationInProgress]: 'invoice-profile-statuses.confirmation-in-progress',
	[InvoiceProfileStatus.TermsOfServiceValidationPending]:
		'invoice-profile-statuses.terms-of-service-validation-pending',
}

export const getTripStatusLabels = (
	t: TFunction,
	approverName?: string,
): Record<TripStatus & TripStatusVirtual & InvoiceProfileStatus, string> => {
	return {
		[TripStatus.Booked]: t('trip-statuses.booked', 'Booked'),
		[TripStatus.Canceled]: t('trip-statuses.canceled', 'Canceled'),
		[TripStatus.Draft]: t('trip-statuses.draft', 'Draft'),
		[TripStatus.DraftBySupport]: t('trip-statuses.draft-by-support', 'Draft'),
		[TripStatus.WaitingApproval]: t('trip-statuses.pending-approval', 'Pending approval'),
		[TripStatus.NotApproved]: t('trip-statuses.rejected', 'Rejected'),
		[TripStatusVirtual.TravelingNow]: t('trip-statuses.traveling-now', 'Traveling now'),
		[TripStatusVirtual.Upcoming]: t('trip-statuses.upcoming', 'Upcoming'),
		[TripStatusVirtual.PastTrip]: t('trip-statuses.past', 'Past'),
		[TripStatus.Reverted]: t('trip-statuses.booking-failed', 'Booking failed'),
		[TripStatus.Booking]: t('trip-statuses.booking', 'Pending Booking'),
		[TripStatus.PriceChanged]: t('trip-statuses.booking', 'Pending Booking'),
		[TripStatus.Approved]: t('trip-statuses.approved', 'Approved'),
		[InvoiceProfileStatus.ConfirmationInProgress]: t(
			'invoice-profile-statuses.confirmation-in-progress',
			'Confirmation in progress',
		),
		[InvoiceProfileStatus.TermsOfServiceValidationPending]: t(
			'invoice-profile-statuses.terms-of-service-validation-pending',
			'Terms and Conditions validation pending',
		),
		[TripStatus.WaitingForUserSubmission]: t('trip-approvers-list.approved-by', 'Approved by {{name}}', {
			name: approverName,
		}),
		[TripStatus.UrgentActionRequired]: t('trip-statuses.urgent-action-required', 'Urgent Action Required'),
	}
}

export type AllTripStatuses =
	| TripStatus
	| TripStatusVirtual
	| ApprovalStatus
	| InvoiceProfileStatus
	| CompanyInvoiceStatus

export type SortTopFlightResults = Record<FlightFilterSet['sortBy'], FlightResult | undefined>
export type SortTopHotelResults = Record<HotelFilterSet['sortBy'], HotelResult>
export type SortTopCarResults = Record<CarFilterSet['sortBy'], CarResult>

export interface TripFilterSet {
	departingFrom: Date | null
	departingTo: Date | null
	destinationName: string | null
	traveler: User | null
	creator: User | null
	office: Office | null
}

export type StopsFilterNumbers = 0 | 1 | 2

export type StopsFilter = Record<StopsFilterNumbers, boolean>

export interface RefundableFilter {
	hideNonRefundable: boolean
	hideNonChangeable: boolean
	hideUnknownChangeable: boolean
	hideUnknownRefundable: boolean
}

export interface FlightTimeRange {
	depart: Date[]
	arrive: Date[]
}

export interface FlightFilterSet {
	stops: StopsFilter
	onlyInPolicy: boolean
	onlyRefundable: RefundableFilter
	cabinClass: Record<string, boolean>
	departureAirports: Record<string, boolean>
	arrivalAirports: Record<string, boolean>
	airlines: Record<string, boolean>
	airlinesMixed: boolean
	hideLcc: boolean
	sortBy: 'lowestCO2' | 'duration' | 'earliestArrival' | 'earliestDeparture' | 'price' | 'recommended'
	price: [number, number] | null
	duration: Array<number[] | null>
	flightUniqueKey: string[]
	times: FlightTimeRange[]
	connectionTime: number[] | undefined
	connectionAirports: Record<string, boolean | undefined>
	providers: Record<string, boolean>
}

export interface HotelTimeRange {
	checkIn: Date[]
	checkOut: Date[]
}

export type HotelSortOptions = 'recommended' | 'price' | 'distance'

export interface HotelFilterSet extends WithName {
	onlyInPolicy: boolean
	sortBy: HotelSortOptions
	stars: Record<string, boolean>
	price: [number, number] | null
	times: HotelTimeRange
	neighborhoods: Record<string, boolean>
	distance: number[] | null
	hotelId: string
	mapBoundingBox: LongtitudeLatitudeBounds | null
	chains: Record<string, boolean>
	hideSoldOut: boolean
}

export interface CarFilterSet {
	onlyInPolicy: boolean
	sortBy: 'recommended' | 'price'
	price: [number, number] | null
	rentalAgency: Record<string, boolean>
	carGroup: Record<string, boolean>
	gear: Record<CarTransmission, boolean>
	mileageUnlimited: boolean
	fuel: Record<CarFuel, boolean>
	vendors: Record<string, boolean>
}

export enum HotelRoomPaymentOptionFilterValue {
	All = 'all',
	PayAtProperty = 'payAtProperty',
	OnlinePrepayment = 'onlinePrepayment',
}

export interface HotelRoomsFilterSet {
	refundable: boolean
	inPolicy: boolean
	breakfastIncluded: boolean
	paymentOption: HotelRoomPaymentOptionFilterValue
	loyalty: boolean
}

export interface HotelSourceFilterSet {
	BookingCom: boolean
	Expedia: boolean
	Sabre: boolean
}

export enum HotelSource {
	BookingCom = 'bookingcom',
	Expedia = 'eps',
	Sabre = 'sabre',
}

export interface DefaultProps extends E2E {
	id?: any
	className?: string
	classes?: any
	style?: React.CSSProperties
	children?: React.ReactNode
	'data-test'?: string
}

export interface IHasClassName {
	className?: string
}

export type ElProps<T extends keyof JSX.IntrinsicElements> = Omit<JSX.IntrinsicElements[T], 'ref'>
export type DefaultElProps<T extends keyof JSX.IntrinsicElements, OMIT extends string | number | symbol = ''> = Omit<
	DefaultProps & ElProps<T>,
	OMIT
>

export type PageProps<
	Params extends Record<string, string | number> = any,
	Route extends Routes | false = false,
> = DefaultProps &
	RouteComponentProps<
		Params extends Record<string, any> ? { [K in keyof Params]: string } : any,
		StaticContext,
		Route extends keyof RouteState ? RouteState[Route] | undefined : unknown
	>

export enum TripDirection {
	OneWay = 'one_way',
	RoundTrip = 'round_trip',
	MultiDestination = 'multi_destination',
}

export type FlightDirection = TripDirection.OneWay | TripDirection.RoundTrip | TripDirection.MultiDestination

export type RailDirection = TripDirection.OneWay | TripDirection.RoundTrip

export interface CountryType {
	code: string
	name?: string | null
}
export interface Passport {
	id: number | null
	type: TravelerDocumentType.Passport
	passport_number: string
	issuing_country: CountryType
	citizenship: CountryType
	issue_date: string | null
	expiration_date: string | null
}

export interface NationalId {
	id: number | null
	type: TravelerDocumentType.National_ID
	number: string
	country: CountryType
	expiration_date: string | null
	foid?: boolean
}

export enum TravelerDocumentType {
	Passport = 'passport',
	National_ID = 'national_id',
}

export interface GeoPoint {
	longitude: number
	latitude: number
}

export interface MapPoint extends GeoPoint, WithName {
	address: string
	location: Location
}

export interface JointMapPoint extends MapPoint {
	points: MapPoint[]
}

export interface SelectOption<T = any, L = React.ReactNode> {
	checked?: boolean
	label: L
	value: T
	description?: React.ReactNode
	disabled?: boolean
}

type RenderFunction<T> = (this: T) => React.ReactNode

export type BindableLabel<T> = React.ReactNode | RenderFunction<T>
export type SelectOptionsBuilder<C, T = any> = SelectOption<T, BindableLabel<C>>

export interface SearchParams {
	limit: number
	last_seen_result_index?: number
}

export interface SearchResult {
	status: LegacyJobStatus
	trip_id: number
	id: number
	flight_results: FlightResults | null
	hotel_results: HotelResults | null
	car_results: CarResults | null
	rails_results: RailResults | null
	price_metadata: PricesMetadata
}

export interface PriceGaugeSet {
	current: PricesBuckets | null
	all: PricesMetadata | null
	label?: React.ReactNode
}

export interface PricesMetadata {
	total: PricesBuckets
	[n: number]: PricesBuckets
}

export interface PricesBuckets {
	0: number
	25: number
	50: number
	75: number
	100: number
}

export interface SearchResults<T = unknown> {
	results: T[]
	last_seen_result_index: number
	max_result_index: number
}

export interface PaginatedSearchResults<T = unknown> extends SearchResults<T> {
	previous_results_ids: number[]
	finished: boolean
	finished_pages: number
	results_finalized?: boolean
	total_pages: number
}

interface FlightResults extends SearchResults<FlightResult> {
	airlines: Airline[]
}

export type RailTransportMode = 'walk' | 'bus' | 'ferry' | 'rapidTransit' | 'train'

export type RailLeg = {
	id: string
	arrive_at: string
	carrier: {
		description: string
		code: string
	}
	depart_at: string
	destination: {
		country_code: string
		location_type: string
		name: string
		time_zone: string
	}
	distance: number
	duration: string
	is_replacement_service: boolean
	origin: {
		country_code: string
		location_type: string
		name: string
		time_zone: string
	}
	platforms: {
		destination: {
			platform: string
		}
		origin: {
			platform: string
		}
	}
	co2_emissions: number
	route: null
	service_information: null
	timetable_id: string
	transport: {
		description: string
		designation: null
		mode: RailTransportMode
	}
}

export type RailResultFareType = {
	description: string
	conditions: Array<{
		name: string
		description: string
	}>
	conditions_summary: string
	validity_period_inward: string | null
	validity_period_outward: string
	secondary_fare: boolean
	fare_pricing_category: string
}

export type RailResultAlternative = {
	id: string
	price: {
		amount: string
		currency: string
		currency_code: string
	}
	full_price: {
		amount: string
		currency: string
		currency_code: string
	}
	fares: Array<{
		price: {
			amount: string
			currency: string
			currency_code: string
		}
		full_price: {
			amount: string
			currency: string
			currency_code: string
		}
		type: RailResultFareType
		category: {
			description: string
			classification: string
		}
		legs: Array<{
			id: string
			travel_class: string
			comfort_class: string | null
		}>
		route_restriction: string | null
		valid_from: {
			outward: string
			inward: string | null
		}
		valid_until: {
			outward: string
			inward: string | null
		}
		origin: {
			country_code: string
			location_type: string
			name: string
			time_zone: string
		}
		destination: {
			country_code: string
			location_type: string
			name: string
			time_zone: string
		}
		local_area_validity: string | null
		discount_card_ids: string[]
		passengers: Array<{
			id: string
			type: {
				age_restriction: {
					lower_bound: number
					upper_bound: number | null
				}
				name: string
			}
		}>
		applied_discount: string | null
		permitted_origins: Array<{
			country_code: string
			location_type: string
			name: string
			time_zone: string
		}>
		permitted_destinations: Array<{
			country_code: string
			location_type: string
			name: string
			time_zone: string
		}>
		availability: {
			status: string
			remaining: number | null
		}
	}>
	flexibility: string | null
	travelling_groups: unknown[]
	included_extras: unknown[]
	vendor: {
		description: string
	}
	inventory: {
		code: 'atoc' | 'sncf' | 'db'
		name: string
	}
	converted_price: {
		amount: number
		currency: Currency
	}
} & WithPolicy

export type RailResult = {
	id: string
	arrive_at: string
	depart_at: string
	direction: string
	duration: string
	legs: RailLeg[]
	provider_name: string
	sections: {
		alternatives: RailResultAlternative[]
	}[]
	unsellable_reason?: {
		long_description: string
		short_description: string
	}
}

export type RailResults = {
	search_id: string
	inward_search_id?: string
	type: string
	passengers: {
		cards: null
		date_of_birth: string
		id: string
		type: {
			age_restriction: {
				lower_bound: number
				upper_bound: number | null
			}
			name: string
		}
	}[]
	expires_at: null
	pagination_is_available: boolean
	outward_journeys?: RailResult[]
	inward_journeys?: RailResult[]
	carriers: {
		description: string
		code: string
	}[]
}

export interface Airline extends WithName {
	id: number | null
	alliance_id: number | null
	is_lcc: boolean | null
	iata: string
	country: Country['name']
}

export interface Airport extends WithName {
	city_code: null | string
	country_iso: string
	country_name: string
	location_type: LocationTypes
	location_data: { city_code?: string; iata?: string }
	iata: string
	id: number
	lat: number
	long: number
	size: string
	code: string
}

export interface Alliance extends WithName {
	id: number
}

export type FlightVendor = 'SABRE' | 'SABRE_US' | 'SABRE-NDC' | 'KIWI' | 'AMADEUS' | 'TRAVELFUSION' | 'FARELOGIX'

export enum ReservationStatus {
	Draft = 'draft',
	Submitted = 'submitted',
	Reverted = 'reverted',
	Booked = 'booked',
	BookFailed = 'book_failed',
	PreBooked = 'prebooked',
}

export enum CancellationStatus {
	NeedToBeCancelled = 'need_to_be_cancelled',
	WaitingCancelConfirmation = 'waiting_cancel_confirmation',
	Cancelled = 'canceled',
	CancellationFailed = 'cancellation_failed',
	Changed = 'changed',
}

export interface WithCancellationStatus {
	cancellation_status: CancellationStatus | null
}

export type Penalties = {
	cancellation_fee_applies: boolean | null
	cancellation_fees: FlightPenaltyFee[] | null
	carrier_fee: boolean | null
	change_itinerary_fee_applies: boolean | null
	change_itinerary_fees: FlightPenaltyFee[] | null
	currency: string | null
	original_currency: string | null
	deposit_refundable: boolean | null
	deposit_required: boolean | null
	failure_to_confirm_fee_applies: boolean | null
	failure_to_confirm_fees: FlightPenaltyFee[] | null
	ticket_changeable: boolean | null
	ticket_refundable: boolean | null
	ticket_replacement_fee_applies: boolean | null
	ticket_replacement_fees: FlightPenaltyFee[] | null
}

export interface CancellationPolicies {
	cancellation_policy: string | null
	change_penalty_after: number | null
	change_penalty_before: number | null
	cancellation_penalty_after: number | null
	cancellation_penalty_before: number | null
	cancellation_due_date: string | null
	ticket_refundable: boolean
	penalties: Penalties | null
}

export interface FlightPenaltyFee {
	allowed: string
	application: string
	guaranteed: string
	max_amount: number
	max_unit: string
	min_amount: number
	min_unit: string
	original_max_amount: number
	original_min_amount: number
	original_total: number
	penalty_type: string
	text: string
	total: number
}

export interface ServiceDiscount {
	is_exclusive: boolean
	is_company_discount: boolean
	label: string | null
	code: string
}

export interface FlightDiscount extends ServiceDiscount {
	is_bcd_nego_fare: boolean
}

export interface HotelDiscount extends ServiceDiscount {
	is_member_only: boolean
	program_name: string | null
	registration_url: string | null
}

export interface FlightFareComponents extends WithFlightDiscounts {
	airline_code: string
	contract_code: null
	fare_basis_code: string
	price_class_code: string | null
	price_class_description: string | null
	price_class_name: string | null
	segment_ids: [string]
	ticket_designator_code: number | null
	is_bcd_negotiated: boolean
	is_company_negotiated: boolean
	is_negotiated: boolean
}

export enum PaymentStatus {
	NotPaid = 'not_paid',
	Failed = 'failed',
}

export enum BookingType {
	ExternalBooking = 'external_booking',
}

export interface WithDiscounts<T> {
	discounts: T[]
}

export type WithFlightDiscounts = WithDiscounts<FlightDiscount>

export type WithHotelDiscounts = WithDiscounts<HotelDiscount>

export type MissingFrequentFlyerInformation = Record<string, { users: PersonNames[] }>

export interface WithTravelers {
	travelers: User[]
}

export interface WithCvvRequirement {
	cvv_required: boolean | null
}

export interface WithBookingType {
	booking_type?: BookingType
	created_by_user?: { id: number } & PersonNames
	created_dt?: string
}

export interface WithBookingSegmentToTravelers {
	booking_segment_to_travelers: BookingSegmentToTraveler[]
}

export interface WithOriginalBookingSegmentId {
	original_booking_segment_id: number | null
}

export type FlightBookingId = number
export type BookingSegmentId = number

export interface FlightResult<T extends FlightResultMinimal<any> = Flight>
	extends Omit<IndexedSearchResult, 'id'>,
		Omit<BookingSegment, 'id'>,
		WithTravelers,
		WithCvvRequirement,
		WithBookingType,
		WithBookingSegmentToTravelers {
	id: FlightBookingId
	fare_group_key: FlightFareGroupKey
	flight_option_keys?: {
		traveler_id: number
		flight_option_keys: FlightOptionKey[]
	}[]
	flights: T[]
	provider: FlightVendor
	/** provider + fare_components ( airline_code + fare_basis_code ) + res_id */
	flight_identifier: string

	//old
	/** total_price + flights ( flight_id ) */
	res_id: string
	total_duration: number
	total_tax: number
	vendor: FlightVendor
	hold_luggage_price: number | null
	travel_safe_rating: number
	recommendation_score: number
	fare_components: FlightFareComponents[]
	missing_ffn_information: MissingFrequentFlyerInformation
	is_active: boolean
	ticket_refundable: boolean
	ticket_by_date: Date | string
	uta: BookingOptionUTA
	validating_carrier_codes: string[]

	external_booking_feedback?: string
}

export enum VendorVerificationStatus {
	Active = 'active',
	Unverified = 'unverified',
	PriceChanged = 'price_changed',
	Canceled = 'canceled',
	Failed = 'failed',
}

export interface BookingSegmentToTraveler extends WithCancellationStatus, WithUserId {
	id: number
	booking_segment_id: number
	vendor_reservation_status: VendorVerificationStatus | null
	vendor_verification_url?: string

	actual_original_total: number | null
	actual_total: number

	original_currency: string
	original_total: number | null

	currency: string
	total_price: number
	total_tax: number

	payment_method_id: number | null
	payment_method: TripPaymentMethod | null

	cloned_booking_errors: ErrorsInBooking[] | null
	within_void_window: boolean | null
	has_unused_tickets: boolean | null

	status: ReservationStatus
	booking_errors: ErrorsInBooking[] | null

	pnr: string

	reservation_details_response?: {
		fare_group: {
			traveler_price: {
				taxes: { original_amount: string; description: string }[]
			}
			validating_carrier_code?: string
		}
	}
}

export interface PolicyDeviation extends WithName {
	res_id?: string
	flight_id?: string[]
	search_result_id?: string[]
	segment_id?: string[]
	traveler_ids: number[]
}

export interface WithPolicy {
	in_policy: boolean | null
	policy_deviations: PolicyDeviation[]
}

export interface FlightResultMinimal<T = MinimalSegment & WithFlightDiscounts> {
	segments: T[]
	id: string
}

export interface Flight extends FlightResultMinimal<Segment> {
	flight_option_key: FlightOptionKey
	duration: {
		hours: number
		minutes: number
	}
	seats: number
	//old
	/** segments ( <all fields hash> ) */
	flight_id: string
	// total_duration: number
	locations: Location[]
	depart_at: string
	land_at: string
	flight_class: string
	flight_airline: Airline
	flight_number: string
	price: number
	luggage_status?: LuggageStatus
}

interface SegmentToUser extends WithUserId {
	seat_type: string | null
	seat: string | null
	airline_confirmation: string | null
	eticket: string | null
}
export interface AirportASB {
	code: string
	location: string
	name?: string
	timezone: string
	title: string
}

export interface Carrier extends WithName {
	code: string
	flight_number: string
	flight_number_suffix: null
	conditions_of_carriage_url?: string
	baggage_policy_url?: string
}
export interface MinimalSegment {
	airplane: string
	aircraft_type: string
	airport_change: boolean
	arrival: TimeAndLocation
	amenities: Amenity[]
	baggage_allowance: BaggageAllowance
	departure: TimeAndLocation
	cabin: CabinClassName
	/** It's fare_component.price_class_name */
	cabin_commercial_name?: string | null
	carrier: Carrier
	operating_carrier: Carrier
	//OLD values
	marketing_airline: Airline
	departure_airport: Airport
	arrival_airport: Airport
	cabin_class: CabinClassName
	departure_time: string
	arrival_time: string
	number_of_travelers: number
	flight_number: string
	bags_recheck_required: boolean
	segment_to_users: SegmentToUser[]
	seat_selection: (SeatMapSeat & WithPolicy)[]
	id: number
	fare_rules?: FareComponent
	emissions: number | null
}

export interface SeatMapSeatDeletion {
	seat_keys: string[]
}

interface StopAirports {
	code: number
	departure_time: string
	arrival_time: string
}

export interface TimeAndLocation {
	airport: AirportASB
	datetime: string
	terminal: string
}

export type BooleanAmenity = 'beverage' | 'entertainment' | 'fresh_food' | 'power' | 'wifi'
export type DescriptionAmenity = 'aircraft' | 'seat' | 'layout'
export type AmenityType = BooleanAmenity | DescriptionAmenity

export type Amenity =
	| {
			display_text: string
			amenity: BooleanAmenity
			exists: boolean
	  }
	| {
			display_text: string
			amenity: DescriptionAmenity
			exists: null
	  }

export interface Segment {
	aircraft_type: string
	airplane: string
	airport_change: boolean
	arrival: TimeAndLocation
	amenities: Amenity[] | null
	departure: TimeAndLocation
	cabin: CabinClassName
	duration: {
		hours: number
		minutes: number
	}
	carrier: Carrier
	operating_carrier: Carrier
	id: string
	/** It's fare_component.price_class_name */
	cabin_commercial_name: string
	emissions: number | null

	//old
	arrival_airport_id: number
	arrival_terminal: null | string
	arrival_time: string
	baggage_allowance: BaggageAllowance
	cabin_class: CabinClassName
	departure_airport_id: number
	departure_terminal: null | string
	departure_time: string
	distance_in_miles: number
	flight_duration: number
	flight_number: string
	marketing_airline_id: number
	marketing_airline?: Airline
	operating_airline_id: number
	operating_airline?: Airline
	plane_type: string
	segment_id: string
	number_of_bags: number
	baggage_weight: string
	baggage_description: string
	vendor_specific_info: VendorSpecificInfo
	bags_recheck_required: boolean
	stop_airports: StopAirports
	segment_id_ref: string
}

export interface BaggageAllowance {
	max_pieces: number
	max_weight: number
	weight_unit: string
}

type VendorSpecificInfo = any

export type HotelResults = PaginatedSearchResults<HotelResult> & {
	bulk_id: string
	page_results: null
	hotels_found_total: number
}

export type CarResults = PaginatedSearchResults<CarResult>

export const CabinClassLabels = {
	premium_first: 'flights.cabin-class-name.premium-first',
	first: 'flights.cabin-class-name.first',
	premium_business: 'flights.cabin-class-name.premium-business',
	business: 'flights.cabin-class-name.business',
	premium_economy: 'flights.cabin-class-name.premium-economy',
	economy: 'flights.cabin-class-name.economy',
	first_train: 'flights.cabin-class-name.first-class-train',
	second_train: 'flights.cabin-class-name.second-class-train',
}

export function getCabinClassLabel({ cabinClassName, t }: { cabinClassName: CabinClassName; t: TFunction }) {
	return {
		premium_first: t('flights.cabin-class-name.first-premium'),
		first: t('flights.cabin-class-name.first'),
		premium_business: t('flights.cabin-class-name.business-premium'),
		business: t('flights.cabin-class-name.business'),
		premium_economy: t('flights.cabin-class-name.premium-economy'),
		economy: t('flights.cabin-class-name.economy'),
		first_train: t('flights.cabin-class-name.first-class-train'),
		second_train: t('flights.cabin-class-name.second-class-train'),
	}[cabinClassName]
}

export const CabinClassCodeLabels = {
	P: CabinClassLabels.premium_first,
	F: CabinClassLabels.first,
	J: CabinClassLabels.premium_business,
	C: CabinClassLabels.business,
	S: CabinClassLabels.premium_economy,
	Y: CabinClassLabels.economy,
}

export type CabinClassName = keyof typeof CabinClassLabels & string
export const CabinClassNames: Record<CabinClassName, CabinClassName> = Object.keys(CabinClassLabels).reduce(
	(a, k) => ({ ...a, [k]: k }),
	{} as Record<CabinClassName, CabinClassName>,
)

export const FLIGHT_CABIN_CLASS_NAMES = [
	CabinClassNames.economy,
	CabinClassNames.premium_economy,
	CabinClassNames.business,
	CabinClassNames.first,
]

export const TRAIN_CABIN_CLASS_NAMES = [CabinClassNames.second_train, CabinClassNames.first_train]

export function getFlightDirectionLabel({ direction, t }: { direction: FlightDirection; t: TFunction }) {
	return {
		[TripDirection.OneWay]: t('flights.flight-directions.one-way', 'One way'),
		[TripDirection.RoundTrip]: t('flights.flight-directions.round-trip', 'Round Trip'),
		[TripDirection.MultiDestination]: t('flights.flight-directions.multi-destination', 'Multi-City'),
	}[direction]
}

export interface HotelSearchResult {
	label: string
	location_type?: LocationTypes
	latitude: number
	longitude: number
}

export interface HotelCitySearchResult extends HotelSearchResult {
	city_name: string
	code: string
	country_iso: string
	country_name: string
}

export interface WithSearchId {
	search_id: number
}

interface IndexedSearchResult extends WithSearchId {
	id: number
	result_index: number
	res_id: string | null
	search: { price_metadata: PricesMetadata }
	currency?: Currency
	local_currency?: Currency
	vendor_specific_info?: VendorSpecificInfo
	raw_data?: any
	readonly __original_total_price?: BillingCost | null
	readonly __original_total_tax?: BillingCost | null
}

export interface HotelResult extends WithPolicy, Omit<IndexedSearchResult, 'id'>, WithName {
	id: string
	is_available: boolean
	min_avg_rate: number
	min_avg_rate_local_currency: number
	min_total: number
	min_total_local_currency: number
	min_rate_breakfast_included: boolean | null
	promo_messages: string[]
	tmc_preferred: boolean
	tmc_preference_tier: number | null
	/** @deprecated */
	client_preferred: boolean
	client_preference_tier: number | null
	warnings: Warning[]
	address: string
	property_amenities: HotelAmenityValues[]
	room_amenities: HotelAmenityValues[]
	chain_info: ChainInfo[]
	check_in_time: string | null
	check_out_time: string | null
	city: string
	country_code: string
	airport_code: string
	direction: string
	distance: number
	hotel_rating: number | null
	iata_guarantee: boolean
	latitude: number
	longitude: number
	phone: string | null
	postal_code: string
	regions: Regions
	sort_metric: SortMetric[]
	state_province_code: null
	thumbnails: string[]
	trustyou_rating: number | null
	is_bcd_great_rate_hotel?: boolean
	is_excluded?: boolean
	liked_users: User[]
	recommendation_score: number
}

type CarVendorSpecificInfo = {
	[key in CarVendor]: VendorInfo
}

export interface WithCarVendorInfo {
	vendor_specific_info?: CarVendorSpecificInfo
	vendor: CarVendor
}

export interface CarResult extends WithPolicy, Omit<IndexedSearchResult, 'vendor_specific_info'>, WithCarVendorInfo {
	total_price: number
	recommendation_score?: number
	car_type: CarType
	pickup_location: CarAgencyLocation
	return_location: CarAgencyLocation | null
	pickup_datetime: moment.MomentInput
	return_datetime: moment.MomentInput
	rent_agency: CarRentAgency
	num_of_seats: number
	large_bags: number | null
	small_bags: number | null
	mileage: Mileage
	thumbnail: string
	cancellation_details?: CarCancellationPolicy | null
	commission: number | string | null
	additional_details: Record<CarAdditionalDetailsName, CarRateDescription[] | null> & {
		special_equip: CarSpecialEquipment[]
		models: any
		address: string | null
		agency_name: string | null
	}
	prepaid: boolean
	prepaid_price?: number
}

export interface HotelDescriptionFields {
	description: string
	short_description: string
	dining: string
	area_attraction: string
	policy: string
	recreation: string
	what_to_expect: string
}

export type HotelWithDescriptions = HotelResult & HotelDescriptionFields

export interface HotelInfo {
	hotel: HotelData
	liked_users: User[]
}

export interface HotelData extends Pick<HotelDescriptionFields, 'description' | 'policy' | 'what_to_expect'>, WithName {
	city: string
	vendor_hotel_id: string
	room_amenities: HotelAmenityValues[]
	address: string
	property_amenities: HotelAmenityValues[]
	country_code: string
	liked_users: User[]
	star_rating: number | null
	rating: number | null
	thumbnails: string[]
	long: number
	lat: number
	checkin_time: string
	checkout_time: string
}

export interface RoomRate extends HotelBooking {
	rate_key: string
	rate_group_name?: string
	loyalty_card_allowed?: boolean
	loyalty_programs?: any[]
	total_price_billed: BillingCost
}

export interface WithClonedFromId {
	cloned_from_id: number | null
}

export interface HotelBooking
	extends WithPolicy,
		IndexedSearchResult,
		BookingSegment,
		WithTravelers,
		WithHotelDiscounts,
		WithClonedFromId,
		WithCvvRequirement {
	room_description: string
	/** total price * `n` nights. divide by night count for avg. price per night */
	total_price: number
	room_amenities: HotelAmenityValues[]
	non_refundable: boolean
	original_total_tax: number
	pay_at_checkout: boolean
	total_tax: number
	original_total_price: number
	cancel_by: string | null
	raw_data: HotelBookingRawData
	average_per_night: number
	original_currency: string
	/** @deprecated */
	client_preferred: boolean
	check_in: string
	check_out: string
	cancellation_due_date: string | null
	payment_cards_accepted: CreditCardType[]

	special_request_allowed: boolean
	special_request_length: number | null
	special_request_type: SpecialRequestType
}

interface HotelBookingRawData extends WithPolicy {
	additional_rate_information: null
	average: number
	cancel_by: string
	cancellation_policy: string
	cancellation_policy_known: boolean
	change_during_stay: boolean
	/** @deprecated */
	client_preferred: boolean
	commission: Commission
	currency: string
	is_bcd_great_rate: boolean
	is_mobile: boolean
	is_package_rate: boolean
	local_average: number
	local_taxes: number
	local_total: number
	members_only: boolean
	non_refundable: boolean
	original_average: number
	original_currency: string
	original_taxes: number
	original_total: number
	paid_policy: string
	payment_options: string[]
	provider: string
	rate_code: string
	rate_company_name: null
	room_description: string
	rate_key: string
	rate_promoline: null
	rate_value_adds: any[]
	room_type_code: string
	tax_mark: string
	taxes: number
	tmc_preferred: boolean
	total: number
	billing_address_required: boolean
	loyalty_programs: any[]
	payment_cards_accepted: PaymentCardsAccepted[]
	special_requests: SpecialRequests
}

export interface LoyaltyProgram {
	loyalty_card_chain_code: string
	loyalty_card_chain_name: string
}

export interface WithLoyaltyProgramName {
	loyalty_program_name: string
}

export type HotelLoyaltyProgramType = 'most_popular' | 'also_popular' | 'other'

export interface HotelLoyaltyProgram extends WithLoyaltyProgramName {
	id: number
	loyalty_program_logo: string | null
	loyalty_program_type: HotelLoyaltyProgramType
	hotel_chain_code: string | null
}

export interface LoyaltyProgramCardBase extends WithUserId {
	id: number
}

export interface HotelLoyaltyProgramCard extends LoyaltyProgramCardBase {
	loyalty_program_code: string
	loyalty_program: HotelLoyaltyProgram
}

export interface CarLoyaltyProgram extends WithLoyaltyProgramName {
	loyalty_program_code: string
}

export interface CarLoyaltyProgramCard extends LoyaltyProgramCardBase {
	car_loyalty_program_id: string
	car_loyalty_program_number: string
	car_loyalty_program: CarLoyaltyProgram
}

export interface ServiceLoyaltyCardFormData {
	loyalty_program_id?: number | string
	loyalty_program_code: string
}

export interface CarLoyaltyCardCreationData {
	car_loyalty_program_id: string
	car_loyalty_program_number: string
}

interface PaymentCardsAccepted {
	code: string
	mandatory_checkout_text: null
}

export enum SpecialRequestType {
	Text = 'text',
	Keys = 'keys',
	KeysAndValues = 'keys_values',
}

interface SpecialRequests {
	sr_allowed: boolean
	sr_length: number
}

interface Warning {
	type: string
	provider?: string
	message: string
}

interface ChainInfo {
	chain_code: string
	chain_name: string
	master_chain_code: string | null
	master_chain_name: string | null
}

interface Regions {
	neighborhood: string[]
}

interface SortMetric extends WithName {
	ascending: boolean
	value: number | null
}

interface Commission {
	commissionable: boolean
}

export interface ItineraryTravelers {
	user: User
	passport_valid: boolean
	documents: TravelerDocuments
	is_passport_expired: boolean
	is_national_ID_expired: boolean
	personal_document_needed: boolean
	document_selection_available: boolean
	has_missing_documents: boolean
	has_missing_date_of_birth: boolean
	has_missing_invoice_profile: boolean
	has_missing_or_incorrect_report_settings: boolean
	has_missing_company_details: boolean
}

export interface Expense {
	expense_date: Date | null
	receipt_url: string | null
	expense_type: ExpenseType | null
	id: number
	trip_id: number
	note: string
	amount: number
	paid_by_user: User | null
}

export type NewExpense = Omit<Expense, 'paid_by_user' | 'id'> & {
	paid_by: number | null
}

/** @deprecated */
export enum ExpenseType {
	Baggage = 'baggage',
	Meals = 'meals',
	Taxi = 'taxi',
	Laundry = 'laundry',
	DryCleaning = 'dry_cleaning',
	ShuttleOrCar = 'shuttle_or_car',
	CarRelated = 'car_related',
	WifiOrCellular = 'wifi_or_cellular',
	Other = 'other',
}

export interface BookingSegment
	extends WithPolicy,
		CancellationPolicies,
		WithCancellationStatus,
		WithReservationStatus,
		WithTravelers,
		WithSearchId {
	id: BookingSegmentId
	total_price: number
	res_id: string | null
}

export type ItineraryFlightBooking = Omit<FlightResult<FlightResultMinimal>, 'res_id'> &
	WithClonedFromId &
	WithOriginalBookingSegmentId &
	WithBookingType & {
		res_id: FlightBookingSegmentResId
	}

export type LateCheckInInterval = [string, string | null]

export interface WithSpecialRequestData {
	arriving_after: LateCheckInInterval | null
	additional_request: string | null
}

export interface ItineraryHotelBooking
	extends HotelBooking,
		WithBookingSegmentToTravelers,
		WithSpecialRequestData,
		WithOriginalBookingSegmentId,
		WithBookingType {
	hotel_id: string
	hotel_name: string
	city: string
}

export interface VendorInfo {
	country_code: string
	exclusive: boolean
	company_discount: boolean
	rate_code?: string
	direct_billing_agreement: boolean
}

export interface ItineraryCarBooking
	extends BookingSegment,
		WithCarVendorInfo,
		WithClonedFromId,
		WithCvvRequirement,
		WithBookingSegmentToTravelers,
		WithBookingType {
	car: CarBooking
	additional_equip: CarSpecialEquipment[]
	extra_booking_data: CarRate | null
}

export interface CarBooking
	extends Pick<
			CarResult,
			| 'res_id'
			| 'id'
			| 'large_bags'
			| 'small_bags'
			| 'car_type'
			| 'pickup_location'
			| 'return_location'
			| 'cancellation_details'
			| 'search'
			| 'vendor'
			| 'prepaid'
			| 'additional_details'
			| 'mileage'
			| 'num_of_seats'
		>,
		WithBookingType {
	fees: CarFee[]
	car_rent_vendor: CarRentAgency
	start_time: string
	end_time: string
	thumbnail: string
}

export type RailVendor = 'DB_BIBE'

export interface RailDestination {
	city: string | null
	country: string
	latitude: number
	longitude: number
	name_en: string | null
	name_local: string | null
}
interface RailItineraryDestination {
	datetime: string
	station: RailDestination
}

interface RailSegmentSelectedSeat {
	amount: number
	currency: Currency
	seat_number: string | null
	coach_number: string | null
	coach_class: string | null
	number_of_adults: number | null
	vat_value_full_rate: number | null
	vat_value_half_rate: number | null
	gross_amount_for_vat_full_rate: number | null
	gross_amount_for_vat_half_rate: number | null
}

export interface RailSegment {
	id: number
	arrival: RailItineraryDestination
	departure: RailItineraryDestination
	modified_from_bibe: boolean
	seat_selection: RailSegmentSelectedSeat[] | null
	train_number: string | null
	train_type: string | null
	vendor_specific_info: string | null
}
export interface Rail {
	age: null
	card_type: string
	rail_class: string
	rail_id: number
	segments: RailSegment[]
	passengers: number | null
	is_ticketed: boolean
}
export interface ItineraryRailBooking
	extends WithPolicy,
		WithCancellationStatus,
		WithReservationStatus,
		WithTravelers,
		WithSearchId,
		WithBookingType {
	bahn_card: string
	cancellation_penalty_before: number | null
	id: number
	ticket_refundable: boolean | null
	fare_type: string
	total_price: number
	total_tax: number
	vendor: RailVendor
	rails: Rail[]
}

export type ItineraryBooking =
	| ItineraryFlightBooking
	| ItineraryHotelBooking
	| ItineraryCarBooking
	| ItineraryRailBooking

export interface ItineraryTripFee {
	user: User
	total_price: number
	total_tax: number
	status: WordlineOrderStatus
	basic_price: number
}

export interface WithItineraryBookingsManagedByUser {
	flights_bookings: ItineraryFlightBooking[]
	hotels_bookings: ItineraryHotelBooking[]
	cars_bookings: ItineraryCarBooking[]
}

export interface WithRailBookings {
	rails_bookings: ItineraryRailBooking[]
}

export type WithItineraryBookings = WithItineraryBookingsManagedByUser & WithRailBookings

export interface WithItineraryTripFees {
	trip_fees?: ItineraryTripFee[]
}

export type WithItineraryServicesManagedByUser = WithItineraryBookingsManagedByUser & WithItineraryTripFees

export interface WithItineraryTravelers {
	travelers: ItineraryTravelers[]
}

export type ItineraryServicesWithTravelers = WithItineraryServicesManagedByUser & WithItineraryTravelers

export interface Itinerary extends ItineraryServicesWithTravelers, WithRailBookings {
	notifications?: NotificationsClass
	hotel_itinerary: HotelItineraryClass
	flight_itinerary: FlightItineraryClass
	car_itinerary: CarItineraryClass
	rail_itinerary: RailItineraryClass
	surcharges: Surcharge[]
	trip_id: number
	trip_name: string
	expenses: Expense[]
	needs_approval: boolean
	approvers: User[]
	hazardous: boolean
	errors?: ErrorsInBooking[]
	totals: {
		seats: number
		trip_cost: number
		post_pay: number
		trip_fees: number
		additional_services: number
		pay_now: number
	}
	trip_notes?: TripNote[]
}

export interface ItineraryChanges {
	flights?: PriceChange[]
	hotels?: PriceChange[]
	cars?: PriceChange[]
}

export interface PriceChange {
	oldPrice: number
	newPrice: number
	res_id?: string | null
	rate_key?: string
}

export interface Surcharge {
	charge_type: ChargeType
	description: string | null
	product_type: SurchargeProductType
	total_price: string
	total_tax: string
	booking_segment_id: number
	is_fully_refunded: boolean
}

export enum ChargeType {
	Baggage = 'baggage',
	Meals = 'meals',
	PreferredSitting = 'preferred_sitting',
	CancellationFee = 'cancellation_fee',
	ChangingFee = 'changing_fee',
	Insurance = 'insurance',
	CarRental = 'car_rental',
	LimousineService = 'limousine_service',
	HandlingFee = 'handling_fee',
	ObFee = 'ob_fee',
	Other = 'other',
}

interface NotificationsClass {
	failed_bookings?: WithItineraryBookings
}

interface BookingConfirmationInfo extends WithUserId {
	confirmation_number: string
}

export interface BookingSegmentsInformation {
	booking_segment_id: number
	booking_confirmation_info: BookingConfirmationInfo[]
}

interface HotelItineraryClass {
	summary: HotelSummary[]
	total_paynow: number
	total_postpay: number
	total_price: number
	total_tax: number
}

interface FlightItineraryClass {
	summary: FlightSummary[]
	total_paynow: number
	total_postpay: number
	total_price: number
	total_tax: number
}

interface CarItineraryClass {
	summary: CarSummary[]
	total_paynow: number
	total_postpay: number
	total_price: number
	total_tax: number
}

interface RailItineraryClass {
	summary: RailSummary[]
	total_paynow: number
	total_postpay: number
	total_price: number
	total_tax: number
}

export interface BookingSummary<T> {
	bs_confirmation_number: string | null
	supplier_confirmation_number: string | null
	booking_segment_id: number
	travelers_ids: number[]
	booking_info: T[]
}

export interface HotelBookingInfo {
	hotel: HotelMinimal
	checkin: string
	checkout: string
	number_of_rooms: number
	pay_at_checkout: boolean
}

export type HotelSummary = BookingSummary<HotelBookingInfo>

interface FlightBookingInfo {
	arrival_airport: Airport
	cabin_class: keyof typeof CabinClassLabels & string
	departure_airport: Airport
	departure_date: string
	number_of_traveler: number
	pay_at_checkout: boolean
}

export interface AirlineConfirmationInfo {
	airline_confirmation_number: string | null
	airline_name: string
}

interface AirlineEticketInfo {
	airline_confirmation_numbers: AirlineConfirmationInfo[] | null
	eticket: string | null
}

export type FlightSummary = Omit<
	BookingSummary<FlightBookingInfo>,
	'bs_confirmation_number' | 'supplier_confirmation_number'
> & {
	bs_confirmation_numbers: Record<string, AirlineEticketInfo>
}

interface CarBookingInfo {
	car_type: CarType
	pickup_location: CarAgencyLocation
	dropoff_location: CarAgencyLocation | null
	pickup_date: string
	return_date: string
	prepaid: boolean
}

type CarSummary = BookingSummary<CarBookingInfo>

interface RailBookingInfo {
	departure_date: string | null
	number_of_traveler: number
	rail_class: string
	departure_station: RailDestination
	arrival_station: RailDestination
}

type RailSummary = BookingSummary<RailBookingInfo>

export interface BookedRoom {
	hotel_id: string
	rate_key: string
	room: RoomRate
}

export interface HotelMinimal extends WithName {
	id: string
	city: string
	country_code: string
	address: string
	thumbnails: string[]
	property_amenities: HotelAmenityValues[]
	room_amenities: HotelAmenityValues[]
	star_rating: HotelResult['hotel_rating']
}

export interface HotelSelectDetails extends WithSpecialRequestData {
	room: RoomRate & Partial<WithOriginalBookingSegmentId>
}

export interface TripGap extends WithTravelers {
	start_time: Date
	end_time: Date
	location: Location
	location_type: LocationTypes
}

export type HotelAmenityValues =
	| 'ACND'
	| 'AIRSH'
	| 'BAR'
	| 'BCNT'
	| 'BFST'
	| 'CCNT'
	| 'CLNG'
	| 'FBFST'
	| 'FINTT'
	| 'FPARK'
	| 'FWIFI'
	| 'GOLF'
	| 'GYM'
	| 'INTT'
	| 'KIDAC'
	| 'KTCH'
	| 'LIFT'
	| 'LNDR'
	| 'MBAR'
	| 'MTNG'
	| 'NSMK'
	| 'PARK'
	| 'PETS'
	| 'POOL'
	| 'RFRG'
	| 'RSTR'
	| 'RSVC'
	| 'SAFE'
	| 'SAUNA'
	| 'TENN'
	| 'TV'
	| 'WCHR'
	| 'WIFI'

export interface HotelPriceCapRoute {
	city_name: string
	country_iso: string
	long: number
	lat: number
	max_rate: number
	radius?: number
}

export enum ApproverSelectionType {
	Any = 'any',
	All = 'all',
}

export enum ApprovalPolicy {
	Always = 'always',
	Never = 'never',
	WhenOutOfPolicy = 'when_out_of_policy',
}

export enum CompanyApprovalSetting {
	Main = 'main',
	GroupApprover = 'group_approver',
	OfficeAdmin = 'office_admin',
	Both = 'both',
	Either = 'either',
}

export enum GroupTypes {
	Department = 'department',
	Office = 'office',
	Company = 'company',
	PolicyGroup = 'policy_group',
	Expense = 'expense',
}

export enum PolicyType {
	General = 'general',
	Flight = 'flights',
	Hotel = 'hotels',
	PreTrip = 'pre_trip',
	Car = 'cars',
}

export function getPolicyTypeLabel({ policyType, t }: { policyType: PolicyType; t: TFunction }) {
	return {
		[PolicyType.Flight]: t('policy-types.flights'),
		[PolicyType.Hotel]: t('policy-types.hotels'),
		[PolicyType.Car]: t('policy-types.cars'),
		[PolicyType.General]: t('policy-types.general'),
		[PolicyType.PreTrip]: t('policy-types.pre-trip'),
	}[policyType]
}

export enum DistanceUnits {
	KM = 'km',
	MI = 'miles',
}

export enum ShortDistanceUnits {
	KM = 'KM',
	MI = 'MI',
}

export function getPolicyGroupLabel({ groupType, t }: { groupType: GroupTypes; t: TFunction }) {
	return {
		[GroupTypes.Company]: t('policy.policy-groups.labels.company'),
		[GroupTypes.Department]: t('policy.policy-groups.labels.department'),
		[GroupTypes.Office]: t('policy.policy-groups.labels.office'),
		[GroupTypes.PolicyGroup]: t('policy.policy-groups.labels.policy-group'),
		[GroupTypes.Expense]: t('policy.policy-groups.labels.expense-policy', 'Expense Policy'),
	}[groupType]
}

export function getApproverLabelByGroupTypeTranslations(
	t: TFunction,
	type: GroupTypes,
	params: { count: number } = { count: 1 },
): string {
	if (type === GroupTypes.PolicyGroup) {
		return t('policy.approver-types.trip', 'Trip Approver', { count: params.count })
	}
	if (type === GroupTypes.Office) {
		return t('policy.approver-types.office', 'Office Approver', { count: params.count })
	}
	return t('policy.approver-types.company', 'Company Approver', { count: params.count })
}

/* NEW POLICY - END */

export enum ApprovalStatus {
	WaitingApproval = 'waiting_approval',
	Approved = 'approved',
	NotApproved = 'not_approved',
	ApprovedBySupport = 'approved_by_support',
}

export const ApprovalStatusLabelMap: Record<ApprovalStatus, string> = {
	[ApprovalStatus.Approved]: 'globals.approval-status-labels.approved',
	[ApprovalStatus.ApprovedBySupport]: 'globals.approval-status-labels.approved_by-support',
	[ApprovalStatus.NotApproved]: 'globals.approval-status-labels.not-approved',
	[ApprovalStatus.WaitingApproval]: 'globals.approval-status-labels.waiting-approval',
}

export interface TripApproval extends WithUserId {
	request_message: string
	message: string
	rejection_reason_type: SurchargeProductType
	approval_status: ApprovalStatus
	user: User
	created_dt: string
}

/** Used in approvals. Logically is BookingSegmentProductType, but with an additional value `all`. Is a separate enum on BE. */
export enum SurchargeProductType {
	All = 'all',
	Flights = 'flights',
	Hotels = 'hotels',
	Cars = 'cars',
}

export enum FlightBookingStatuses {
	Failed = 'failed',
	Booked = 'booked',
	Waiting = 'pending',
}

export enum CarBookingStatuses {
	Failed = 'failed',
	Booked = 'booked',
	Waiting = 'pending',
}

export interface PriceChangesData {
	total_price: number
	total_tax: number
	old_price: number
	old_tax: number
}

export interface ItineraryRefresh {
	id: number
	status: 'created' | 'sent' | 'success' | 'failed' | 'terms_changed'
}

export interface ReportsOverviewData {
	period: string
	overview: Overview
	flights: ReportsOverviewFlights
	hotels: ReportsOverviewHotels
	cars: ReportsOverviewCars
	rails: ReportsOverviewRails
	travelers: ReportsOverviewTravelers
}

export interface OptionWithId extends WithName {
	id: number
}

export interface ReportsMetaDataBase {
	countries: OptionWithId[]
	invoice_profiles: OptionWithId[]
	departments: (string | null)[]
	offices: (string | null)[]
}

export interface ReportsMetaData extends ReportsMetaDataBase {
	accounting_unit: (string | null)[]
	action_code: (string | null)[]
	billable: (string | null)[]
	cost_center: (string | null)[]
	internal_account: (string | null)[]
	project_code: (string | null)[]
	purchase_order_number: (string | null)[]
	trip_purpose: string[]
}

export interface ReportsOverviewFlights extends WithReportFlightCabinClass {
	total_airfare: number
	fare_type: FareType
	direct_flights: ValueTotal
	flexible: ValueTotal
	flights_per_trip_type: FlightsPerTripTypeElement[]
}

export type RailCabinClassValueTotal = {
	first_train: number
	second_train: number
	total: number
}

export type RailFareTypeStats = {
	tickets: number
	seats: number
	total: number
}

export type ReportsOverviewRails = {
	cabin_class: RailCabinClassValueTotal
	fare_type: RailFareTypeStats
	trips_per_type: RailsPerTripTypeElement[]
	total_rail_spend: number
}

export type RailsPerTripTypeElement = {
	key: string
	avg_ticket_price: number
	booked_in_advance: number
	cabin_class: RailCabinClassValueTotal
	num_travelers: number
	num_trips: number
	total_rail_spend: number
	trips_by_path?: RailsPerTripTypeElement[]
}

export type SpecificRailsPerTripTypeElement = Omit<RailsPerTripTypeElement, 'trips_by_path'> & {
	end_date: string
	start_date: string
	trip_id: number
}

export interface ReportsOverviewTravelers {
	booked_by_traveler: ValueTotal
	booked_in_advance: number
	per_traveler: PerTravelerStats[]
	rejected: ValueTotal
	sent_for_approval: ValueTotal
	travelers_count: number
	trips_count: number
}
export interface ReportsFlightCabinClassValueTotal {
	economy: number
	business: number
	first: number
	premium_economy: number
	total: number
}

export interface WithReportFlightCabinClass {
	cabin_class: ReportsFlightCabinClassValueTotal
}

export type WithReportRailCabinClass = {
	cabin_class: RailCabinClassValueTotal
}

export type WithReportCabinClass = WithReportFlightCabinClass | WithReportRailCabinClass

export type ReportCabinClassValueTotal = ReportsFlightCabinClassValueTotal | RailCabinClassValueTotal

export type ReportCabinClassKey = keyof ReportsFlightCabinClassValueTotal | keyof RailCabinClassValueTotal

interface ValueTotal {
	value: number
	total: number
}

interface Breakfast {
	free: number
	payed: number
	total: number
}

interface FareType {
	tickets: number
	total: number
}

export enum HaveChanges {
	none = 'none',
	changed = 'changed',
	cancelled = 'cancelled',
}

export function getTripChangesLabel(haveChanges: HaveChanges | string, t: TFunction) {
	return (
		{
			[HaveChanges.none]: t('trip-changes.none', 'None'),
			[HaveChanges.changed]: t('trip-changes.changed', 'Changed'),
			[HaveChanges.cancelled]: t('trip-changes.cancelled', 'Cancelled'),
		}[haveChanges] || capitalize(haveChanges)
	)
}

export interface FlightsPerTripTypeElement extends WithReportFlightCabinClass {
	key: string
	emissions_total: number
	num_trips: number
	total_airfare: number
	num_travelers: number
	avg_ticket_price: number
	direct_flights: ValueTotal
	booked_in_advance: number
	flexible: ValueTotal
	flights_by_path?: FlightsPerTripTypeElement[]
	have_changes: HaveChanges
	changes: ValueTotal
	change_or_cancel_fees: number
}

export interface SpecificFlightsPerTripTypeElement extends FlightsPerTripTypeElement {
	start_date: string
	end_date: string
	flights_by_path: never
}

export interface PerTravelerStats {
	cabin_class: ReportCabinClassValueTotal
	key: number
	traveler: { full_name: string; image_url: string }
	num_nights: number
	total_spend: number
	trips_count: number
	flexible: ValueTotal
	booked_in_advance: number
	avg_ticket_price: number
	avg_night_price: number
	booked_by_traveler: ValueTotal
	have_changes: HaveChanges
	changes: ValueTotal
	change_or_cancel_fees: number
}

// Generated by https://quicktype.io

export interface PerTravelerTrip extends WithName {
	key: string
	hotels: TravelerTripHotel[]
	total_spend: number
	flights: TravelerTripFlight[]
	rails: TravelerTripRail[]
	cars: TravelerTripCar[]
	change_or_cancel_fees: number
	have_changes: string
	booked_in_advance: number
	booked_by_traveler: TravelerTripsBookedByTraveler
	flexible: TravelerTripsBookedByTraveler
	start_date: string
	end_date: string
}

interface TravelerTripsBookedByTraveler {
	total: number
	value: number
}

interface TravelerTripFlight extends WithName, WithReportFlightCabinClass {
	total_spend: number
	change_or_cancel_fees: number
	have_changes: string
	booked_in_advance: number
	booked_by_traveler: FlightBookedByTraveler
	avg_ticket_price: number
	flexible: FlightBookedByTraveler
}

export type TravelerTripRail = WithName & {
	avg_ticket_price: number
	booked_by_traveler: ValueTotal
	booked_in_advance: number
	cabin_class: RailCabinClassValueTotal
	change_or_cancel_fees: 0
	flexible: { value: 0; total: 1 }
	have_changes: 'none'
	total_spend: number
}

interface TravelerTripCar extends WithName {
	booked_by_traveler: FlightBookedByTraveler
	booked_in_advance: number
	change_or_cancel_fees: number
	flexible: FlightBookedByTraveler
	have_changes: string
	total_spend: number
	num_days: number
}

interface FlightBookedByTraveler {
	value: number
	total: number
}
interface TravelerTripHotel extends WithName {
	total_spend: number
	change_or_cancel_fees: number
	have_changes: string
	avg_rating: number
	booked_in_advance: number
	booked_by_traveler: FlightBookedByTraveler
	num_nights: number
	avg_night_price: number
	flexible: FlightBookedByTraveler
}

export interface ReportsOverviewHotels {
	total_hotel_fare: number
	num_nights: number
	num_hotels: number
	avg_nights: number
	avg_rating: number | null
	avg_night_price: number | null
	refundable: ValueTotal
	breakfast: Breakfast
	hotels_per_city: HotelsPerCity[]
}

export interface HotelsPerCity {
	key: string
	num_trips: number
	total_hotel_fare: number
	num_travelers: number
	num_nights: number
	avg_nights: number
	avg_night_price: number
	avg_rating: number | null
	booked_in_advance: number
	num_hotels: number
	refundable: ValueTotal
	breakfast: Breakfast
	have_changes: HaveChanges
	changes: ValueTotal
	change_or_cancel_fees: number
}

export interface HotelsPerCityDetails extends HotelsPerCity {
	city_with_country: string
	per_trip: HotelsPerTrip[]
	hotel_name: string
}

interface HotelsPerTrip extends HotelsPerCity {
	start_date: string
	end_date: string
}

interface CarPerTrip extends CarPerCity {
	start_date: string
	end_date: string
}

export interface CarsPerCityDetails extends CarPerCity {
	country: string
	city_or_airport: string
	rent_agency: string
	per_trip: CarPerTrip[]
	display_name: string
}

export interface ReportsOverviewCars {
	total_car_spend: number
	num_days: number
	num_cars: number
	avg_days: number
	avg_rate: number | null
	refundable: ValueTotal
	cars_per_city: CarPerCity[]
}

export interface CarPerCity {
	key: string
	num_trips: number
	total_car_spend: number
	num_days: number
	avg_days: number
	avg_rate: number
	num_cars: number
	refundable: ValueTotal
	have_changes: HaveChanges
	booked_in_advance: number
	changes: ValueTotal
	change_or_cancel_fees: number
	rent_agency: string
}

interface ExpiringTicketsBreakdownGroup {
	count: number
	total: number
}

export interface ExpiringTicketsBreakdown {
	less_than_one_month: ExpiringTicketsBreakdownGroup
	one_to_three_months: ExpiringTicketsBreakdownGroup
	three_to_six_months: ExpiringTicketsBreakdownGroup
	more_than_six_months: ExpiringTicketsBreakdownGroup
}

export interface UnusedTicketsOverview {
	expiring_tickets_breakdown: ExpiringTicketsBreakdown
	active: ExpiringTicketsBreakdownGroup
	expired: ExpiringTicketsBreakdownGroup
	exchanged: ExpiringTicketsBreakdownGroup
}

export interface Overview extends UnusedTicketsOverview {
	emissions_flights: number
	total_airfare: number
	total_hotel_fare: number
	total_car_spend: number
	total_spend: number
	total_rail_spend: number
	trips_count: number
	travelers_count: number
	destinations_count: number
	in_policy: ValueTotal
	with_pre_trip: ValueTotal
	cancelled_trips: ValueTotal
	sent_for_approval: ValueTotal
	rejected: ValueTotal
	time_plan_to_submit: number | null
	time_submit_to_approval: number | null
	num_flights: number
	miles_flown: number
	num_hotels: number
	num_cars: number
	num_nights: number
	num_car_days: number
	num_rails: number
	avg_rating: number | null
	top_travelers: TopTravelers
	top_countries: TopCountries
	pre_trips: PreTripReport
	purpose: TripPurposeCounter[]
	purpose_list: TripPurposeOption[]
}

interface PreTripReport {
	approved_pre_trip_requests: number
	total_pre_trip_requests: number
	pre_trips_enabled: boolean
}

type TopCountries = {
	[k in keyof typeof CountriesCode]: number
} & {
	total: number
}

interface TopTravelers {
	[k: string]: number
	total: number
}

export function getSurchargeLabel(charge: Surcharge, t: TFunction, params?: { productType: string }): string {
	const map: Record<Surcharge['charge_type'], string> = {
		baggage: t('flights.surcharge-labels.baggage', 'Additional baggage'),
		cancellation_fee: t('flights.surcharge-labels.cancellation-fee', '{{productType}} cancellation fee', params),
		car_rental: t('flights.surcharge-labels.car-rental', 'Car rental'),
		changing_fee:
			Number(charge.total_price) >= 0
				? t('flights.surcharge-labels.changing-fee', '{{productType}} changing fee', params)
				: t('flights.surcharge-labels.changing-fee-refund', '{{productType}} refund', params),
		handling_fee: t('flights.surcharge-labels.handling-fee', 'Trip processing fee'),
		insurance: t('flights.surcharge-labels.insurance', 'Travel insurance'),
		limousine_service: t('flights.surcharge-labels.limousine-service', 'Limousine service'),
		meals: t('flights.surcharge-labels.meals', 'Additional flight meals'),
		other: t('flights.surcharge-labels.other', 'Other ancillary changes'),
		preferred_sitting: t('flights.surcharge-labels.preferred-sitting', 'Preferred seating charges'),
		ob_fee: t('flights.surcharge-labels.ob-fee', 'Airline credit card fee (OB)'),
	}

	return map[charge.charge_type] || capitalize(charge.charge_type.replace('_', ' '))
}

export const getCleanPhoneNumber = (phoneNumber: string) => phoneNumber.replace(/[^0-9+]/g, '')

export const HEADER_HEIGHT = 100
export const MOBILE_HEADER_HEIGHT = 50

export const WEBSITE_URL_BY_LOCALE: Record<Locales, string> = {
	[Locales.enUS]: `https://${WEBSITE_BASE}`,
	[Locales.enGB]: `https://${WEBSITE_BASE}`,
	[Locales.deDE]: `https://${WEBSITE_BASE}/de`,
}

export const DETAILED_PRICING_URL_BY_LOCALE: Record<Locales, string> = {
	[Locales.enUS]: `${WEBSITE_URL_BY_LOCALE[Locales.enUS]}/pricing/#allfeatures`,
	[Locales.enGB]: `${WEBSITE_URL_BY_LOCALE[Locales.enGB]}/pricing/#allfeatures`,
	[Locales.deDE]: `${WEBSITE_URL_BY_LOCALE[Locales.deDE]}/preise/#allfeatures`,
}

enum SortDirection {
	Asc,
	Desc,
}

export { SortDirection }

export interface SortDef<T = any> {
	key: T
	direction: SortDirection
}

export interface Group extends WithName {
	id: number
	approver: User
	approver_user_id: number
	users_count: number
	type: GroupTypes
}

interface CreditCardData {
	country_code: Country
	billing_email: string
	first_name: string
	last_name: string
	billing_address: string
	billing_city: string
	billing_phone: string
	billing_zip: string
	billing_state: string
}
export interface AddCreditCardParams {
	name_on_card?: string
	session_token: string
	cc_temp_token?: string
	card_name?: string
	billing_info_id: number
	card_data: CreditCardData
	user_token_id: string
	user_payment_option_id: string
}

type TypedMap<K extends string, T = any> = {
	[k in K]: T
}

export type CallbackMap<K extends string, R = any, T = any> = TypedMap<K, Callback<R, T>>
export type EmptyCallbackMap<K extends string, R = any> = TypedMap<K, EmptyCallback<R>>

export interface ResultSegment {
	key: string
	label: string
	filterFn(item: any): boolean | null
	itemsLength?: number
	alwaysExpanded?: boolean
	icon?: React.ReactNode
}

export interface SeatMapCollection {
	seg_seq_num: number
	departure: string
	arrival: string
	seat_map: SeatMap
	status: SeatPollingStatus
	user_ids: number[]
	booking_segment_id: number
}

interface SeatMap {
	cabins: Cabin[]
}

interface Cabin {
	location: string
	column_names: CabinColumnName[]
	rows: CabinRow[]
	wing: WingDetails
}

enum CabinColumnName {
	Empty = ' ',
	A = 'A',
	B = 'B',
	C = 'C',
	D = 'D',
	E = 'E',
	F = 'F',
	G = 'G',
	H = 'H',
	I = 'I',
	J = 'J',
	L = 'L',
	M = 'M',
}

interface CabinRow {
	row_number: number | null
	is_exit: boolean
	is_wing: boolean
	cells: CabinCell[]
}

export interface CabinCell extends WithPolicy {
	cell_type: CabinCellType
	// raw_data: RawData | null
	cell_name: null | string
	seat_status: SeatStatus | null
	seat_extra_cost: number | null
	seat_premium: boolean | null
}

enum CabinCellType {
	Aisle = 'AISLE',
	Galley = 'GALLEY',
	Lavatory = 'LAVATORY',
	None = 'NONE',
	Seat = 'SEAT',
}

enum SeatStatus {
	Vacant = 'AVAILABLE',
	Occupied = 'OCCUPIED',
	Blocked = 'BLOCKED',
}

interface WingDetails {
	first_row: number
	last_row: number
}

enum SeatPollingStatus {
	Pending = 'IN_PROCESS',
	Success = 'SUCCESS',
	Fail = 'FAILED',
}

/**
 * This class mirrors [React.Component], except it adds [muiName: string] public static variable
 * @see React.Component
 */
export class MUIComponent<P = {}, S = {}> extends React.Component<P, S> {
	public static muiName: string
}

/**
 * This type mirrors [React.ComponentClass], except it adds [muiName: string] public static variable
 * @see React.ComponentClass
 */
export type MUIComponentClass<P = {}, S = {}> = React.ComponentClass<P, S> & { muiName: string }

/**
 * This type mirrors [React.FunctionComponent], except it adds [muiName: string] property
 * @see React.FunctionComponent
 */
export type MUIFunctionComponent<P = {}> = React.FunctionComponent<P> & { muiName: string }

export type ElementEvent<C = Element, T = Event, E = EventTarget> = React.BaseSyntheticEvent<T, E & C, C>

export enum BillingPeriod {
	Monthly = 'monthly',
	Annual = 'annual',
}

export interface PackageTrip extends Trip {
	is_merged: boolean
}

export interface PackageUsageData {
	amount_of_paid_support_request_fees?: number
	amount_of_paid_trip_fees?: number
	amount_of_paid_user_fees?: number
	country_code: CountriesCode
	country_currency: Currency
	country_name: string
	free_support_request_amount_one_time?: number | null
	free_support_request_amount_time_period?: number | null
	free_support_request_renewal_date?: string | null
	free_trip_amount_one_time?: number | null
	free_trip_amount_time_period?: number | null
	free_trip_renewal_date?: string | null
	free_user_amount_one_time?: number | null
	free_user_amount_time_period?: number | null
	free_user_renewal_date?: string | null
	package_commercial_name: string
	renewal_date: string
	start_date: string
	support_request_fee_price?: number
	support_request_fee_unlimited: boolean
	total_amount_of_basic_fees: number
	total_amount_of_total_fees: number
	total_amount_of_vats: number
	trip_fee_price?: number
	trip_fee_unlimited: boolean
	user_fee_price?: number
	user_fee_unlimited: boolean
	users_type?: 'active' | 'profiled'
}

export enum PackageChangeDirection {
	Up = 'upgrade',
	Down = 'downgrade',
}

export interface LiveUserLocation {
	trip_id: number
	traveler: User
	location: Location
	location_name: string
	verified: boolean
	user_tracking_id: number
}

export interface UserTrackingQS {
	user_tracking_id: number
	status: 'confirmed' | 'declined'
}

export interface CompanyPreference {
	preference_type: CompanyPreferenceType
	enabled: boolean
	description: string
	excluded_users: User[]
	included_users: User[]
}

export interface CompanyPreferenceUpdateData {
	preference_type: CompanyPreferenceType
	enabled: boolean
	add_included_users: number[]
	remove_included_users: number[]
	add_excluded_users: number[]
	remove_excluded_users: number[]
}

export interface HomePageData {
	is_trip_approver: boolean
	is_pre_trip_green_light_mode: boolean
	waiting_to_approve: PreTripRequest[]
	expense_reports_waiting_to_approve: ExpenseReportApi[]
	pre_trip_requests: PreTripRequest[]
	number_of_waiting_trip_approval: number
	total_number_of_pre_trip_approval: number
	total_number_of_trip_approval: number
	has_missing_profile_details: boolean
	number_of_waiting_for_user_submission: number
	is_passport_expired: boolean
	is_national_ID_expired: boolean
	number_of_failed_fees: number
}

export interface PreTripRequest extends WithTravelers, WithName {
	id: number
	route: PreTripRoute[]
	purpose: TripPurpose[]
	notes: string
	pre_trip_approvals: PreTripApproval[]
	status: ApprovalStatus
	created_dt: string
}

interface PreTripApproval {
	approver: User
	approval_status: ApprovalStatus
	approval_status_dt: string
	message: string | null
}

export interface PreTripRequestUpdateData {
	name?: string
	route: PreTripRoute[]
	purpose: TripPurpose[]
	notes: string
	travelers_ids: number[]
}

export interface State extends WithName {
	code: string
}

export interface PreTripRequestManageData {
	message: string
	status: ApprovalStatus
}

export interface PreTripRoute {
	from_location_id: LocationId
	to_location_id: LocationId
	from_location: Location
	to_location: Location
	from_date: string
	to_date: string
}

export interface CompanyTypeModal {
	showModal: boolean
}

export enum SafetyLabels {
	LiveMap = 'live-map',
}

export interface ItineraryFareData {
	id: number
	fare_group_key?: FlightFareGroupKey
	fare_options_keys?: FlightOptionKey[]
}

type SeatMapTab = number | null
export interface ItinerarySeatMapState {
	selectedSeats: SeatMapSegmentsSelection | {}
	seatMapOpen: boolean
	seatMapTab: SeatMapTab
	flightSeatSelection: SeatMapFlightResultSelection | {}
	seat: SeatMapSeat | undefined | null
	flightOptionKeys: ItineraryFareData[]
	flightSegments: Record<string, MinimalSegment[][]> | {}
	res_id: Record<number, string> | {}
	seatMapSelection: SeatMapSegmentsSelection | null
	emergencyRowSelections: SeatMapSegmentsSelection | {}
	showDeleteSeatConfirmation: boolean
	isMultipleFlight: boolean
	selectedFlightId: number
}

export interface CancellationDetails {
	cancellationStatus: CancellationStatus | null
	supportRequest?: SupportRequest
}

export type CancellationFlightOrHotelDetails = SupportRequest[]

export const POSCountryName = z.string()

export interface POSCountry {
	name: z.infer<typeof POSCountryName>
	currency: Currency | null
	code: CountriesCode
	iso_code: string | null
	phone_code: string | null
	id: number
}

export interface CustomerSuccessConsultant {
	first_name: string
	middle_name: string | null
	last_name: string
	phone: string | null
	email: string | null
	hubspot_id: string | null
	photo_url: string | null
}

export interface UserPOS {
	id: number
	email: string | null
	whatsapp: string | null
	telegram: string | null
	contact_phone: string | null
	terms_of_services_de: string | null
	terms_of_services_us: string | null
	terms_of_services_uk: string | null
	operational_manager_email: string | null
	country: POSCountry
	agency_legal_entity_name: string | null
	agency_legal_entity_trade_register_number: string | null
	agency_tax_number: string | null
	agency_legal_entity_address: string | null
	agency_sap_company_code: string | null
	agency_iata_arc_number: string | null
	standard_high_tax_rate: number | null
}

export type CountryPOSStatus = 'active' | 'inactive'

export type CountryPOSRegion = 'Europe' | 'Americas' | 'APAC'

export type POSType = 'PRIMARY' | 'VIRTUAL'

export interface CountryPOS {
	id: number
	status: CountryPOSStatus
	agency_additional_information: string[]
	agency_iata_arc_number: string
	agency_legal_entity_address: string
	agency_legal_entity_name: string
	agency_legal_entity_trade_register_number: string
	agency_sap_company_code: string
	agency_tax_number: string | null
	agency_travel_agent_registration_number: string | null
	contact_phone: string
	country: POSCountry
	default_asb_entity_id: string
	default_language: string
	email: string
	invoice_profiles_count: number
	official_currency: Currency
	operational_manager_email: string | null
	region: CountryPOSRegion
	standard_high_tax_rate: string | null
	telegram: string | null
	terms_of_services_de: string
	terms_of_services_uk: string
	terms_of_services_us: string
	travel_hub_merchant_id: string | null
	whatsapp: string | null
	units: ShortDistanceUnits
	primary_pos_id: number | null
	pos_type: POSType
}

export enum SearchType {
	Flights = 'flights',
	Rails = 'rails',
	Mixed = 'mixed',
}

export type SearchVariant =
	| 'home' // Full form
	| 'sidebar'
	| 'peek' // Like the home, but shows only direction inputs initially

export interface ISearchVariant {
	variant?: SearchVariant
}

export enum TripPurpose {
	ClientMeeting = 'client_meeting',
	ExternalConference = 'external_conference',
	InstallationMeeting = 'installation_meeting',
	InternalEvent = 'internal_event',
	InternalMeeting = 'internal_meeting',
	/** This is a special value used only in the report counter */
	NoPurpose = 'no_reason',
	OffsiteProject = 'offsite_project',
	Other = 'other',
	Recruitment = 'recruitment',
	SalesMeeting = 'sales_meeting',
	SupplierMeeting = 'supplier_meeting',
	Training = 'training',
}

export interface TripPurposeOption {
	value: TripPurpose
	label: string
}

export interface TripPurposeCounter {
	key: string
	count: number
}

export interface EssentialTravelInformation {
	title: string
	short_description: string
	description: string
}

export const BRAND_SPECIFIC = {
	SMART_PRICE_POLICY: 'GoSmart',
}

export interface Office extends WithName {
	address: string
	city_name: string
	contact_email?: null
	contact_name?: null
	contact_phone?: null
	country_code: string
	created_dt: string
	description?: null
	group_id: number
	has_policy: boolean
	id: number
	latitude: number
	longitude: number
	office_approver?: null
	office_type: string
	state_name: string
}

export interface TravelerDocuments {
	national_ids: NationalId[] | []
	passports: Passport[] | []
}

export type NationalIdWithDocumentType = NationalId & { documentType: TravelerDocumentType }
export type PassportWithDocumentType = Passport & { documentType: TravelerDocumentType }
export type TravelerDocumentsWithType = NationalIdWithDocumentType | PassportWithDocumentType
export interface SelectedDocuments extends WithUserId<number | null> {
	document_id: number | null
	document_type: TravelerDocumentType
}
export interface UserIdAndDocumentType {
	userId: number | null
	documentType: TravelerDocumentType
}
export type ExtendedNationalId = NationalIdWithDocumentType & { userId: number | null }
export type ExtendedPassport = PassportWithDocumentType & { userId: number | null }

export enum DynamicFormNodeType {
	Text = 'text',
	Select = 'select',
	AnyOf = 'any_of',
	Group = 'group',
}

export interface DynamicFormNodeBase {
	type: DynamicFormNodeType
	e2e: string
	hint: string | null
	info: string | null
	label: string | null
	title: string | null
	title_text: string | null
}

export interface DynamicFormFieldBaseAttributes extends WithName {
	mandatory: boolean
}

export type DynamicFormFieldBase = DynamicFormNodeBase & DynamicFormFieldBaseAttributes

export interface DynamicFormField extends DynamicFormFieldBase {
	id: number
	default_value: string | null
	disabled_reason?: string
}

export interface DynamicFormTextField extends DynamicFormField {
	min_length: number | null
	max_length: number | null
	last_two_digits: boolean
	first_three_letters: boolean
}

export interface DynamicFormSelectOption {
	label: string
	value: string
}

export interface DynamicFormSelectField extends DynamicFormField {
	options: DynamicFormSelectOption[]
}

export interface DynamicFormFieldsGroup extends DynamicFormNodeBase {
	fields: (DynamicFormTextField | DynamicFormSelectField)[]
}

export type DynamicFormAnyOfField = DynamicFormFieldsGroup & DynamicFormFieldBaseAttributes

export type DynamicFormDefinition = (
	| DynamicFormAnyOfField
	| DynamicFormSelectField
	| DynamicFormTextField
	| DynamicFormFieldsGroup
)[]

export interface RewardProgramLevel {
	id: number
	value: string
	key: string
	hint: string
	info: string
}

export interface FlightRewardProgramFormField extends DynamicFormTextField {
	reward_program_level: RewardProgramLevel | null
}

export interface FlightRewardProgramFieldGroup extends WithName {
	label: string | null
	id: number
	hint: string | null
	info: string | null
	flight_program_form_fields_list: FlightRewardProgramFormField[]
	one_of_fields_is_required: boolean
	title: string | null
	title_text: string | null
}

export interface AirlineRewardProgram {
	airline_display: {
		iata: string
		id: number
	} & WithName
	id: number
	reward_program: {
		discount_type: string
		id: number
		individual_membership_required: boolean
		program_levels: RewardProgramLevel[]
	} & WithName
	is_program_exists: boolean
	disabled_reason: string | null
}

interface CompanyFlightRewardField {
	field_value: string
	reward_field: FlightRewardProgramFormField
	id: number
}
export interface CompanyFlightProgram {
	id: number
	airline_reward: AirlineRewardProgram
	fields: CompanyFlightRewardField[]
	reward_program_level: RewardProgramLevel | null
}

interface CompanyFlightProgramFieldApiDataBase {
	airline_reward_id: number
	field_value: string
}

interface WithRewardProgramLevel {
	reward_program_level_id?: number
}

interface WithFlightRewardProgramLevel {
	flight_reward_program_field_id: number
}

export type CompanyFlightProgramFieldApiData = CompanyFlightProgramFieldApiDataBase &
	WithRewardProgramLevel &
	WithFlightRewardProgramLevel

export type CompanyFlightProgramCreationFormData = CompanyFlightProgramFieldApiData[]

export interface CompanyFlightProgramUpdateFormData {
	company_reward_program_id: number
	fields: (
		| (CompanyFlightProgramFieldApiDataBase & {
				company_reward_program_field_id: number
		  })
		| (CompanyFlightProgramFieldApiDataBase & WithFlightRewardProgramLevel)
	)[]
	reward_program_level_id?: number
}

export interface WithSabreRateAccessCode {
	sabre_rate_access_code: string
}

export interface CompanyHotelProgram extends WithSabreRateAccessCode {
	id: number
	display_name: string
	description: string | null
}

export type CompanyHotelProgramCreationData = Omit<CompanyHotelProgram, 'id'>

export interface CompanyCarProgram {
	id: number
	corporate_discount_code: string
	car_loyalty_program: CarLoyaltyProgram
	direct_billing_agreement: string | null
}

export interface CompanyCarProgramCreationData {
	car_loyalty_program_code: string
	corporate_discount_code: string
	direct_billing_agreement: string | null
}

export enum UnusedTicketStatus {
	Active = 'ACTIVE',
	Exchanged = 'EXCHANGED',
	Expired = 'EXPIRED',
}

export type NamedIdentifiable = PersonNames & Identifiable

export interface UnusedTicket {
	validating_airline: Airline
	ticket_number: string
	ticket_issue_dt: string
	ticket_expiration_dt: string
	exchanged_on_ticket: UnusedTicket | null
	currency: Currency
	unused_credit_amount: number
	trip_id: number
	status: UnusedTicketStatus
	user: NamedIdentifiable
	redirect_to_trip_allowed: boolean
}

export interface UnusedTicketsResponse {
	travelers_count: number
	rows: UnusedTicket[]
}

export type ReportRowUnusedTicket = Omit<UnusedTicket, 'user' | 'exchanged_on_ticket'> & {
	user: NamedIdentifiable & Pick<User, 'status'>
	airline_confirmation_number: string
	exchanged_on_ticket: ReportRowUnusedTicket | null
	stops: string
	country_code: string
}

export interface UnusedTicketsRowGroup {
	count: number
	total: number
	rows: ReportRowUnusedTicket[]
}

export interface UnusedTicketsReportData {
	active: UnusedTicketsRowGroup
	exchanged: UnusedTicketsRowGroup
	expired: UnusedTicketsRowGroup
}

export enum HTTPMethod {
	GET = 'GET',
	POST = 'POST',
	PUT = 'PUT',
	DELETE = 'DELETE',
	PATCH = 'PATCH',
}

export enum FareAvailability {
	Available = 'available',
	NotEnoughSeats = 'not_enough_seats',
	NotAvailable = 'not_available',
}

export interface BookingSegmentAvailability {
	fare_availability: FareAvailability
	booking_segment_id: number
}

export interface WithJobId {
	job_id: string
}

export interface AlternativeFares<T> {
	alternative_fares_job_id: string
	alternative_fares: T[] | null
}

export interface ServicesAvailabilityResponse {
	status: 'sent' | 'success'
	fare_availabilities: BookingSegmentAvailability[]
}

export type HotelAvailabilityResult = {
	status: string
	result: { price_metadata: PricesMetadata; results: HotelResult[] }
}

export interface BookingSegmentToTravelerFakeBookingSetting {
	booking_segment_to_travelers_id: number
	mock: string
}

export interface TripFakeBookingSettings {
	mocks: BookingSegmentToTravelerFakeBookingSetting[]
}

export type BookingsAvailabilityData = Record<number, BookingSegmentAvailability>

export interface WithJobStatus {
	status: JobStatus
}

export interface WithResult<T> {
	result: T
}

export type JobResult<T> = WithJobStatus &
	WithResult<T | null> & {
		errors: string[]
	}

export interface FlightFaresResult {
	fare_groups: FlightResult[]
}

export interface HotelFaresResult {
	rates: RoomRate[]
}

export type AlternativeFlightFaresJobResult = JobResult<FlightFaresResult>

export type AlternativeHotelFaresJobResult = JobResult<HotelFaresResult>

export type AlternativeFlightData = FlightResult<Flight>

export type AlternativeHotelData = { rate: RoomRate } | { hotel: HotelResult }

export type UserPaymentMethods = WithUserId & WithCreditCards

export type CardCvcData = Record<string, string>

export interface WithCardCvcData {
	card_cvc_data: CardCvcData
}

export interface WithFeeCreditCardId {
	fees_credit_card_id?: number
}

export type TravelerPaymentMethod = WithUserId & WithCreditCardId

export type ServicePaymentMethods = Partial<{
	flights_credit_card_id: number
	cars_credit_card_id: number
	hotels_credit_card_id: number
}>

export type ServicePaymentMethodsWithFeeCard = WithFeeCreditCardId & ServicePaymentMethods

export type TravelerServicePaymentMethods = ServicePaymentMethodsWithFeeCard & WithUserId

export interface WithMultipleCreditCards<T> {
	multiple_credit_cards: T[]
}

export type SeparateCardPayload = TravelerPaymentMethod | TravelerServicePaymentMethods

export type MultipleCreditCardsPayload = WithMultipleCreditCards<SeparateCardPayload>

export type TripPaymentMethodConfigPayload = MultipleCreditCardsPayload | WithCreditCardId

export enum FareSearchOption {
	AllResults = 'all',
	FullyFlexible = 'flexible',
	Changeable = 'changeable',
	Refundable = 'refundable',
	ChangeableAndRefundable = 'changeable and refundable',
}

export const TripNoteTypes = ['PRIVATE', 'PUBLIC', 'SYSTEM'] as const

export type TripNote = {
	is_agent_only: boolean
	note: string
	last_modified_dt: string
	created_by_user: PersonNames & Pick<User, 'email' | 'id' | 'image_url'>
	trip_id: number
	id: number
	type: (typeof TripNoteTypes)[number]
	created_dt: string
}

export type TimeOptions = 'past-3-months' | 'current-year' | 'last-year' | 'past-month' | 'past-year' | 'all-time'
