import { z } from 'zod'

export const FlightBookingOptionResIdZ = z.string().brand<'FlightBookingOptionResId'>()

export type FlightBookingOptionResId = z.infer<typeof FlightBookingOptionResIdZ>

export const FlightBookingSegmentResIdZ = z.string().brand<'FlightBookingSegmentResId'>()

export type FlightBookingSegmentResId = z.infer<typeof FlightBookingSegmentResIdZ>

export const FlightFareGroupKeyZ = z.string().brand<'FlightFareGroupKey'>()

export type FlightFareGroupKey = z.infer<typeof FlightFareGroupKeyZ>

export const FlightOptionKeyZ = z.string().brand<'FlightOptionKey'>()

export type FlightOptionKey = z.infer<typeof FlightOptionKeyZ>

export const FlightSearchIdZ = z.number() // .brand<'FlightSearchId'>()

export type FlightSearchId = z.infer<typeof FlightSearchIdZ>

export const FlightUniqueKeyZ = z.string().brand<'FlightUniqueKey'>()

export type FlightUniqueKey = z.infer<typeof FlightUniqueKeyZ>
