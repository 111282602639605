import { AxiosResponse } from 'axios'
import { z } from 'zod'

import { appConfig } from 'src/app-config/appConfig'
import { asyncWait } from 'src/lib/asyncWait'
import { createGetGoingAuthFailedResponseInterceptor, createGetGoingAuthRequestInterceptor } from 'src/lib/auth'

import { BaseApiClient, BaseRequestConfig, EnforceRelativeURL } from '../BaseApiClient'

const POLL_ACTION = Symbol()

class GetGoingClient extends BaseApiClient {
	constructor() {
		super(appConfig.API_DOMAIN)

		this.client.interceptors.request.use(createGetGoingAuthRequestInterceptor())
		this.client.interceptors.response.use(undefined, createGetGoingAuthFailedResponseInterceptor(this.client))
	}

	/**
	 * Only polls. You need to create a job first by yourself.
	 * Defaults:
	 * - maxAttempts = 300
	 * - pollDelay = 2 sec
	 *
	 * Together they give 10 min of polling until an error.
	 */
	async pollJobResult<URL extends string, ResZ extends z.ZodTypeAny, R>(
		url: EnforceRelativeURL<URL>,
		config: BaseRequestConfig<z.ZodType<void>, ResZ> & {
			mapResult: (job: AxiosResponse<z.infer<ResZ>>, poll: typeof POLL_ACTION) => R | typeof POLL_ACTION
			maxAttempts?: number
			pollDelay?: number
		},
	): Promise<R> {
		// 300 attempts of 2 secs sums up as 10 minutes.
		const { mapResult, maxAttempts = 300, pollDelay = 2000, ...reqConfig } = config
		const getResult = () => this.request('GET', url, reqConfig).then((job) => mapResult(job, POLL_ACTION))

		let attempt = 1
		let result = await getResult()

		while (result === POLL_ACTION) {
			if (attempt > maxAttempts) {
				throw new Error('Request has been aborted')
			}
			await asyncWait(pollDelay)
			result = await getResult()
			attempt++
		}

		return result
	}
}

let client: GetGoingClient

export function getGGClient() {
	if (!client) {
		client = new GetGoingClient()
	}
	return client
}
