import { z } from 'zod'

export enum JobStatus {
	Sent = 'sent',
	Failed = 'failed',
	Completed = 'completed',
	InProgress = 'in progress',
}

export const JobStatusZ = z.nativeEnum(JobStatus)

export const CreateJobResponseZ = z.object({
	job_id: z.string(),
	status: JobStatusZ,
})

export type CreateJobResponse = z.infer<typeof CreateJobResponseZ>

export function getJobResultZ<T extends z.ZodTypeAny>(resultSchema: T) {
	return z.discriminatedUnion('status', [
		z.object({
			status: z.literal(JobStatus.Sent),
		}),
		z.object({
			status: z.literal(JobStatus.InProgress),
		}),
		z.object({
			status: z.literal(JobStatus.Completed),
			result: resultSchema,
		}),
		z.object({
			status: z.literal(JobStatus.Failed),
			errors: z.array(z.string()),
		}),
	])
}
