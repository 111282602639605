import React from 'react'

import {
	accentLight,
	accentLight30,
	accentLight60,
	accentLightShadow,
	accentPrimary,
	accentTertiary,
	bgMaterial,
	bgMaterialAlt,
	bgMaterialAlt70,
	black10,
	boxShadowFloat,
	currentColor,
	fgContrast,
	fgPrimary,
	fgPrimary30,
	fgPrimary40,
	fgPrimary50,
	transparent,
} from 'src/_vars'
import { MobileClickArea } from 'src/atoms/MobileClickArea'
import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import {
	backgroundGray,
	blue700,
	brightRed,
	darkRed,
	darkTurquoise,
	disabledGray,
	fontBlack,
	linkBlue,
	purple,
	secondaryBlack,
	white,
	yellow,
} from 'src/refactor/colors'
import { font, FontSize, FontWeight } from 'src/refactor/fonts'
import styled, { css, flex, padMulti, padPx, size } from 'src/styles'
import { DefaultElProps } from 'src/travelsuit'

type PaddingSizes = 'xs' | 'sm' | 'md' | 'lg'

export interface IProps extends DefaultElProps<'button'> {
	color?: Colors
	rounded?: boolean | number | string
	paddingSize?: PaddingSizes | number[]
	mobilePaddingSize?: PaddingSizes | number[]
	fullWidth?: boolean
	noWrap?: boolean
	width?: number | string
	height?: number
	fontSize?: FontSize
	fontWeight?: FontWeight
	disabled?: boolean
	minWidth?: number
	withoutBorder?: boolean
	withoutShadow?: boolean
	hideMobileClickArea?: boolean
	isActive?: boolean
}

const PaddingMap: Record<PaddingSizes | 'rounded', number[]> = {
	xs: [4, 14],
	sm: [8, 20],
	md: [14, 28],
	lg: [17, 32],
	rounded: [10, 20],
}

const FontSizeMap: Record<PaddingSizes | 'rounded', FontSize> = {
	xs: 12,
	sm: 14,
	md: 16,
	lg: 18,
	rounded: 14,
}

const BackgroundColorMap = {
	primary: accentPrimary,
	primaryLight: accentLight,
	negative: brightRed,
	negativeTransparent: transparent,
	secondary: bgMaterial,
	tertiary: accentTertiary,
	dark: fgPrimary40,
	primaryInverse: 'none',
	background: bgMaterial,
	activeFilter: linkBlue,
	transparent,
	menuItem: transparent,
	menuItemError: transparent,
	yellow: yellow,
	purple: purple,
	linkBlue: linkBlue,
	disabled: disabledGray,
	transparentBlue: bgMaterial,
	bluePrimary: linkBlue,
	blueOutline: transparent,
	noColor: transparent,
	whiteOutline: bgMaterial,
}

const HoverBackgroundColorMap: Partial<Record<Colors, string>> = {
	primary: darkTurquoise,
	primaryLight: accentLight,
	negative: darkRed,
	negativeTransparent: brightRed,
	secondary: backgroundGray,
	tertiary: accentTertiary, // TODO: update
	dark: fgPrimary30,
	primaryInverse: accentLight30,
	background: bgMaterialAlt,
	activeFilter: linkBlue,
	transparent: accentPrimary,
	menuItem: darkTurquoise,
	menuItemError: darkRed,
	yellow: '#D69418',
	purple: purple,
	linkBlue: linkBlue,
	disabled: disabledGray,
	transparentBlue: linkBlue,
	bluePrimary: blue700,
	blueOutline: backgroundGray,
	noColor: transparent,
}

const ActiveBackgroundColorMap: Partial<Record<Colors, string>> = {
	primary: darkTurquoise,
	primaryLight: accentLightShadow,
	negative: darkRed,
	negativeTransparent: darkRed,
	secondary: backgroundGray,
	tertiary: accentTertiary, // TODO: update
	dark: fgPrimary50,
	primaryInverse: accentLight60,
	background: bgMaterialAlt70,
	activeFilter: linkBlue,
	transparent: black10,
	menuItem: darkTurquoise,
	menuItemError: darkRed,
	yellow: '#D69418',
	purple: purple,
	linkBlue: linkBlue,
	disabled: disabledGray,
	transparentBlue: linkBlue,
	bluePrimary: blue700,
	blueOutline: backgroundGray,
	noColor: transparent,
}

const ForegroundColorMap: Record<Colors, string> = {
	primary: fgContrast,
	primaryLight: fgContrast,
	negative: fgContrast,
	negativeTransparent: fgPrimary,
	secondary: fontBlack,
	tertiary: fgContrast,
	dark: fgContrast,
	primaryInverse: accentPrimary,
	background: fgPrimary,
	activeFilter: white,
	transparent: currentColor,
	menuItem: currentColor,
	menuItemError: currentColor,
	yellow: fgContrast,
	purple: fgContrast,
	linkBlue: fgContrast,
	disabled: fgContrast,
	transparentBlue: linkBlue,
	bluePrimary: fgContrast,
	blueOutline: linkBlue,
	noColor: currentColor,
	whiteOutline: fontBlack,
}

const BorderColorMap: {
	[K in Colors]?: string
} = {
	secondary: secondaryBlack,
	transparentBlue: linkBlue,
	blueOutline: linkBlue,
	menuItem: transparent,
	whiteOutline: secondaryBlack,
}

const HoverBorderColorMap: {
	[K in Colors]?: string
} = {
	secondary: secondaryBlack,
	blueOutline: linkBlue,
}

const ActiveBorderColorMap: {
	[K in Colors]?: string
} = {
	secondary: secondaryBlack,
	blueOutline: linkBlue,
}

const DisabledBorderColorMap: {
	[K in Colors]?: string
} = {
	blueOutline: secondaryBlack,
}

const DisabledBackgroundColorMap: {
	[K in Colors]?: string
} = {
	blueOutline: transparent,
}

function isPaddingSize(key: any): key is PaddingSizes {
	return Object.getOwnPropertyNames(PaddingMap).includes(key)
}

export type Colors = keyof typeof BackgroundColorMap

const StyledButton = styled.button<IProps>`
	cursor: pointer;
	position: relative;

	color: ${(p) => (p.color ? ForegroundColorMap[p.color] : '')};
	background: ${(p) => (p.color ? BackgroundColorMap[p.color] : '')};
	${(p) =>
		p.noWrap &&
		css`
			white-space: nowrap;
		`};
`

export const ButtonBase = React.forwardRef<HTMLButtonElement, IProps>(function ButtonBase(
	{ children, hideMobileClickArea, ...rest },
	ref,
) {
	return (
		<StyledButton {...rest} ref={ref}>
			{hideMobileClickArea ? null : <MobileClickArea />}
			{children}
		</StyledButton>
	)
})

const Button = styled(ButtonBase).attrs<IProps>(addE2EAttrs)<IProps>`
	border: ${(props) =>
		props.withoutBorder ? 'none' : `1px solid ${BorderColorMap[props.color!] || BackgroundColorMap[props.color!]}`};
	${(props) => {
		switch (props.color) {
			case 'blueOutline':
			case 'whiteOutline':
				return css`
					border-width: 2px;
				`
			default:
				return undefined
		}
	}}

	padding: ${(props) =>
		props.rounded
			? isPaddingSize(props.paddingSize)
				? padMulti(PaddingMap[props.paddingSize!])
				: PaddingMap.rounded
			: isPaddingSize(props.paddingSize)
				? padMulti(PaddingMap[props.paddingSize!])
				: padMulti(props.paddingSize ?? PaddingMap.md)};

	${(p) => p.theme.breakpoints.down('md')} {
		padding: ${(props) =>
			props.mobilePaddingSize
				? props.rounded
					? isPaddingSize(props.mobilePaddingSize)
						? padMulti(PaddingMap[props.mobilePaddingSize!])
						: PaddingMap.rounded
					: isPaddingSize(props.mobilePaddingSize)
						? padMulti(PaddingMap[props.mobilePaddingSize!])
						: padMulti(props.mobilePaddingSize ?? PaddingMap.md)
				: ''};

		min-height: ${(p) => (p.rounded ? '50px' : '40px')};
	}

	${(props) =>
		font({
			size:
				props.fontSize ??
				(props.rounded
					? FontSizeMap.rounded
					: isPaddingSize(props.paddingSize)
						? FontSizeMap[props.paddingSize!]
						: FontSizeMap.md),
			weight: props.fontWeight ?? 'bold',
		})}
	border-radius: ${(props) => padPx(typeof props.rounded === 'boolean' && props.rounded ? 30 : props.rounded || 5)};
	line-height: 1.3;
	cursor: pointer;
	transition: all 150ms ease-in-out;
	${(props) => (props.fullWidth ? 'width: 100%;' : '')}
	${(props) => (props.width ? `width: ${padPx(props.width)};` : '')}
	${(props) => (props.height ? `height: ${padPx(props.height)};` : '')}

	${(props) =>
		['transparent', 'negativeTransparent', 'transparentBlue'].includes(props.color!)
			? `
		border-color: currentColor;

		${
			!props.disabled
				? css`
						&:hover {
							color: white;
							border-color: ${HoverBackgroundColorMap[props.color!]};
						}

						&:active {
							color: ${HoverBackgroundColorMap[props.color!]};
						}
					`
				: ''
		}
	`
			: ''}

	${(props) =>
		props.disabled
			? css`
					color: ${secondaryBlack};
					cursor: default;
					background: ${DisabledBackgroundColorMap[props.color!] || disabledGray};
					border-color: ${DisabledBorderColorMap[props.color!] || disabledGray};
				`
			: css`
					&:hover {
						background-color: ${HoverBackgroundColorMap[props.color!]};
						border-color: ${HoverBorderColorMap[props.color!] || HoverBackgroundColorMap[props.color!]};
						box-shadow: ${!props.withoutShadow && boxShadowFloat};
					}

					&:active {
						background-color: ${ActiveBackgroundColorMap[props.color!]};
						border-color: ${ActiveBorderColorMap[props.color!] || HoverBackgroundColorMap[props.color!]};
					}
				`}

	${(props) =>
		!props.disabled && props.isActive
			? css`
					background-color: ${HoverBackgroundColorMap[props.color!]};
					border-color: ${HoverBorderColorMap[props.color!] || HoverBackgroundColorMap[props.color!]};
					box-shadow: ${!props.withoutShadow && boxShadowFloat};
				`
			: ''}

	&:focus {
		outline: none;
	}

	${(props) => (props.minWidth ? `min-width: ${padPx(props.minWidth)};` : '')}
`

Button.defaultProps = {
	color: 'secondary',
	paddingSize: 'md',
}

export type RoundedButtonProps = IProps & {
	diameter?: number | string
	fontSize?: FontSize
	borderRadius?: number
}

export const RoundedButton = styled(Button)<RoundedButtonProps>`
	${(props) => (props.diameter ? size(props.diameter) : '')}
	border-radius: ${(p) => p.borderRadius ?? 5}px;
	${(props) => (props.fontSize ? font({ size: props.fontSize }) : font({ size: 12 }))};
`

interface IconButtonProps extends DefaultElProps<'button'>, E2E {}

export const IconButton = styled(ButtonBase).attrs<IconButtonProps>(addE2EAttrs)<IconButtonProps>`
	flex: 0 0 auto;
	text-align: center;
	border: 0;
	padding: 0;
	background: transparent;
	cursor: pointer;
	transition: all 150ms ease-in-out;

	${({ disabled }) =>
		disabled
			? `
	cursor: default;
	opacity: 0.4;
	`
			: `
			&:hover {
				color: ${linkBlue};
			}`}
`

export const TextButton = styled(ButtonBase).attrs(addE2EAttrs)<
	E2E & { fontColor?: string; suppressHoverColor?: boolean }
>`
	padding: 0;

	color: ${(p) => p.fontColor ?? ''};

	&[disabled] {
		color: ${disabledGray};
	}

	&:not([disabled]):hover {
		color: ${(p) => (p.suppressHoverColor ? '' : linkBlue)};
	}

	border: none;
	background: none;

	transition: all 150ms ease-in-out;

	&:hover {
		background: none;
		box-shadow: none;
	}
`

export const FlexButton = styled(Button)`
	${(p) => flex({ justify: 'space-between', align: 'center', gap: p.theme.spacing(2) })}
`

export type FlexButtonProps = React.ComponentPropsWithRef<typeof FlexButton>

export const LinkButton = styled(TextButton).attrs({ fontColor: linkBlue })``

export default Button
